<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start p0"
  >
    <Waiting v-if="waiting" :message="$t('waiting.payment_started')" />
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t(`checkout.payment`)"
      />
      <transition name="fade" mode="out-in" appear>
        <div>
          <div class="text-center">
            <div class="text-secondary fs-15">
              {{ $t(`product.total_price`) }}
            </div>
            <div class="text-color-primary fs-29 font-PM">
              {{ formatPrice(sumPrice) }}
            </div>
          </div>
          <div
            class="d-flex flex-column w-100 align-items-center justify-content-center text-center color-text-secondary p-4 bg-color-gray radius-12"
          >
            <div class="d-flex justify-content-between w-100">
              <div>{{ $t(`product.total_price`) }}</div>
              <div>{{ formatPrice(sumPrice) }}</div>
            </div>
          </div>
          <div>
            <div class="pb-4">
              <div class="font-PR fs-20 pb-2 mt-3">
                {{ $t(`checkout.pay_at_the_counter`) }}
              </div>
              <div class="my-2">
                <button
                  class="btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center"
                  @click="paymentMethod = PAYMENT_METHOD.POS_TERMINAL"
                  :class="
                    paymentMethod === PAYMENT_METHOD.POS_TERMINAL
                      ? 'active'
                      : ''
                  "
                >
                  <div class="flex-shrink py-2 px-3">
                    <img
                      src="@/assets/images/pos-terminal-icon.png"
                      width="40px"
                      height="auto"
                      alt=""
                    />
                  </div>
                  <div class="flex-fill py-2">
                    <p class="font-PR text-start ps-3 fs-18 my-0">
                      {{ $t(`checkout.pay_with_credit_card`) }}
                    </p>
                  </div>
                  <div class="checkbox flex-shrink py-2">
                    <div><span></span></div>
                  </div>
                </button>
              </div>
              <div class="my-2">
                <button
                  class="btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center"
                  @click="paymentMethod = PAYMENT_METHOD.CASH"
                  :class="paymentMethod === PAYMENT_METHOD.CASH ? 'active' : ''"
                >
                  <div class="flex-shrink py-2 px-3">
                    <img
                      src="@/assets/images/cash-icon.png"
                      width="40px"
                      height="auto"
                      alt=""
                    />
                  </div>
                  <div class="flex-fill py-2">
                    <p class="font-PR text-start ps-3 fs-18 my-0">
                      {{ $t(`checkout.pay_with_cash`) }}
                    </p>
                  </div>
                  <div class="checkbox flex-shrink py-2">
                    <div><span></span></div>
                  </div>
                </button>
              </div>
              <div class="font-PR fs-20 pb-2 mt-3">
                {{ $t(`checkout.payment_online`) }}
              </div>
              <div class="my-2">
                <button
                  class="btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center"
                  @click="paymentMethod = PAYMENT_METHOD.ONLINE_BARION"
                  :class="
                    paymentMethod === PAYMENT_METHOD.ONLINE_BARION
                      ? 'active'
                      : ''
                  "
                >
                  <div class="flex-shrink py-2 px-3">
                    <img
                      src="@/assets/images/online-payment-icon.png"
                      width="40px"
                      height="auto"
                      alt=""
                    />
                  </div>
                  <div class="flex-fill py-2">
                    <p class="font-PR text-start ps-3 fs-18 my-0">
                      {{ $t(`checkout.pay_with_barion`) }}
                    </p>
                  </div>
                  <div class="checkbox flex-shrink py-2">
                    <div><span></span></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="w-100 mt-auto mx-auto maxw-600">
            <div
              class="w-100 d-flex flex-row align-items-center justify-content-between"
            >
              <button
                @click="checkout"
                class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
              >
                {{ $t(`btn.checkout_now`) }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import HeaderNav from "../components/HeaderNav.vue";
import { PAYMENT_METHOD } from "@/config/variables";
import TableEventUser from "../repositories/TableEventUser";
import Waiting from "../components/Waiting.vue";
import TableSessionRepository from "../repositories/TableSessionRepository";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Checkout",
  components: {
    HeaderNav,
    Waiting,
  },
  computed: {
    PAYMENT_METHOD() {
      return PAYMENT_METHOD;
    },
    ...mapState(["cart"]),
    ...mapGetters([
      "getOrder",
      "getTableSessionObject",
      "getCartSumPrice",
      "getLang",
      "getIsKiosk",
      "getIsGuest",
    ]),
  },
  data() {
    return {
      waiting: false,
      sumPrice: 0,
      sumUUID: null,
      orders: [],
      paymentMethod: PAYMENT_METHOD.POS_TERMINAL,
    };
  },
  created() {
    if (!this.getIsKiosk && !this.getIsGuest) {
      this.getSummary();
    } else {
      this.sumPrice = this.getCartSumPrice;
    }
  },
  methods: {
    async getSummary() {
      const data = {
        tableIdToSummarize: this.getTableSessionObject.tableId,
        receiptLanguageCode: this.getLang,
      };
      const response = await TableSessionRepository.summary(data);
      this.sumPrice = response.sumPrice;
      this.sumUUID = response.summaryUuid;
      console.log(response);
    },
    async checkout() {
      this.waiting = true;

      let items = this.cart.map((item) => ({
        productId: item.id,
        productOptionIds: item.optionIds,
        productQuantity: item.qty,
        comment: item.comment,
        exceptBaseOptionIds: item.exceptBaseOptionIds,
        baseOptionIds: item.baseOptionIds,
        extraOptionIds: item.extraOptionIds,
        extraProductIds: item.extraProductIds,
      }));

      const order = {
        eventValue: this.getOrder.type,
        eventItems: [...items],
      };

      const data = {
        receiptLanguage: this.getLang,
        paymentMethod: this.paymentMethod,
        order: order,
      };
      console.log("DATA:", data);
      const checkout = await TableEventUser.prepaid(data);

      if (checkout.success) {
        setTimeout(
          async function () {
            switch (this.paymentMethod) {
              case PAYMENT_METHOD.CASH:
              case PAYMENT_METHOD.POS_TERMINAL:
                this.$router.push({
                  name: "CheckoutGuestWaiting",
                  params: { order: checkout.data },
                });
                break;
              case PAYMENT_METHOD.ONLINE_BARION:
                if (checkout?.data?.value && this.getIsGuest == true) {
                  window.location.href = checkout?.data?.value;
                } else {
                  alert("TODO implement QR code from Barion payment URL!");
                }
                break;
            }
          }.bind(this),
          300
        );
      } else {
        //success false
      }
    },
  },
};
</script>
