<template>
  <div>
    <vue-bottom-sheet
      ref="actionSheet"
      :background-scrollable="false"
      :background-clickable="false"
      max-width="600px"
      max-height="400px"
      @closed="element = false"
    >
      <div class="px-4 pb-4 pt-2" v-if="sheetState === 0">
        <h1 class="font-PR fs-20 pb-3">
          {{ $t(`action_sheet.what_can_i_help`) }}
        </h1>

        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="actions(CLIENT_EVENT.CALL_STAFF)"
          >
            {{ $t(`btn.call_waiter`) }}
          </button>
        </div>

        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="actions(CLIENT_EVENT.PAYMENT_REQUEST)"
          >
            {{ $t(`action_sheet.pay`) }}
          </button>
        </div>
        <div class="my-3">
          <router-link
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            v-bind:to="{ name: 'Orders' }"
          >
            {{ $t(`action_sheet.orders`) }}
          </router-link>
        </div>
        <div class="text-center">
          <button
            class="btn btn-link font-PR fs-18 color-text-primary clear-link text-center"
            @click="close"
          >
            {{ $t("btn.cancel") }}
          </button>
        </div>
      </div>
      <div class="px-4 pb-4 pt-2" v-if="sheetState === 2">
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center"
          style="width: 100px; height: 100px"
        >
          <img
            src="@/assets/images/waiting-icon.svg"
            width="25"
            height="auto"
            alt=""
            class="hourglass-rotation"
          />
        </div>
        <div class="my-4">
          <p class="text-center color-text-secondary fs-20 font-PR mb-0 py-3">
            {{ $t("action_sheet.waiter_coming") }}
          </p>
        </div>
        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="close"
          >
            {{ $t("action_sheet.ok") }}
          </button>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { CLIENT_EVENT } from "@/config/variables";
import TableEventUser from "../../repositories/TableEventUser";

export default {
  name: "action-sheet",
  props: ["value"],
  components: {
    VueBottomSheet,
  },
  data() {
    return {
      sheetState: 0,
    };
  },
  computed: {
    CLIENT_EVENT() {
      return CLIENT_EVENT;
    },
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    element: function () {
      this.sheetState = 0;
      if (this.element === true) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    open() {
      this.element = true;
      this.$refs.actionSheet.open();
    },
    close() {
      this.element = false;
      this.$refs.actionSheet.close();
    },
    actions: function (action) {
      switch (action) {
        case CLIENT_EVENT.CALL_STAFF:
          this.callStaff();
          break;
        case CLIENT_EVENT.PAYMENT_REQUEST:
          this.goCheckout();
          break;
        default:
          break;
      }
    },
    async callStaff() {
      const response = await TableEventUser.call();
      if (response.success) {
        this.sheetState = 2;
      }
    },
    goCheckout() {
      this.sheetState = 0;
      this.$router.push({ name: "Checkout" });
    },
  },
};
</script>

<style lang="scss">
.bottom-sheet__card {
  height: 100vh !important;

  .bottom-sheet__content {
    height: 100% !important;
  }
}

.payment-method {
  .checkbox {
    div {
      width: 30px;
      height: 30px;
      border: 1px solid var(--primary-color);
      border-radius: 50%;

      span {
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
    }
  }

  &.active {
    .checkbox {
      div {
        padding: 1px;

        span {
          background-color: var(--primary-color);
        }
      }
    }
  }
}
</style>
