var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex bg-color-white flex-column align-items-start justify-content-start p0",attrs:{"id":"page"}},[(_vm.waiting)?_c('Waiting',{attrs:{"message":_vm.$t('waiting.payment_started')}}):_vm._e(),_c('div',{staticClass:"mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"},[_c('HeaderNav',{attrs:{"backButtonBg":'bg-color-gray',"backAction":() => {
          this.$router.go(-1);
        },"title":_vm.$t(`checkout.payment`)}}),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-secondary fs-15"},[_vm._v(" "+_vm._s(_vm.$t(`product.total_price`))+" ")]),_c('div',{staticClass:"text-color-primary fs-29 font-PM"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.sumPrice))+" ")])]),_c('div',{staticClass:"d-flex flex-column w-100 align-items-center justify-content-center text-center color-text-secondary p-4 bg-color-gray radius-12"},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',[_vm._v(_vm._s(_vm.$t(`product.total_price`)))]),_c('div',[_vm._v(_vm._s(_vm.formatPrice(_vm.sumPrice)))])])]),_c('div',[_c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"font-PR fs-20 pb-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t(`checkout.payment_waiter`))+" ")]),_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center",class:_vm.paymentMethod === _vm.PAYMENT_METHOD.POS_TERMINAL
                    ? 'active'
                    : '',on:{"click":function($event){_vm.paymentMethod = _vm.PAYMENT_METHOD.POS_TERMINAL}}},[_c('div',{staticClass:"flex-shrink py-2 px-3"},[_c('img',{attrs:{"src":require("@/assets/images/pos-terminal-icon.png"),"width":"40px","height":"auto","alt":""}})]),_c('div',{staticClass:"flex-fill py-2"},[_c('p',{staticClass:"font-PR text-start ps-3 fs-18 my-0"},[_vm._v(" "+_vm._s(_vm.$t(`checkout.pay_with_credit_card`))+" ")])]),_c('div',{staticClass:"checkbox flex-shrink py-2"},[_c('div',[_c('span')])])])]),_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center",class:_vm.paymentMethod === _vm.PAYMENT_METHOD.CASH ? 'active' : '',on:{"click":function($event){_vm.paymentMethod = _vm.PAYMENT_METHOD.CASH}}},[_c('div',{staticClass:"flex-shrink py-2 px-3"},[_c('img',{attrs:{"src":require("@/assets/images/cash-icon.png"),"width":"40px","height":"auto","alt":""}})]),_c('div',{staticClass:"flex-fill py-2"},[_c('p',{staticClass:"font-PR text-start ps-3 fs-18 my-0"},[_vm._v(" "+_vm._s(_vm.$t(`checkout.pay_with_cash`))+" ")])]),_c('div',{staticClass:"checkbox flex-shrink py-2"},[_c('div',[_c('span')])])])]),_c('div',{staticClass:"font-PR fs-20 pb-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t(`checkout.payment_online`))+" ")]),_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center",class:_vm.paymentMethod === _vm.PAYMENT_METHOD.ONLINE_BARION
                    ? 'active'
                    : '',on:{"click":function($event){_vm.paymentMethod = _vm.PAYMENT_METHOD.ONLINE_BARION}}},[_c('div',{staticClass:"flex-shrink py-2 px-3"},[_c('img',{attrs:{"src":require("@/assets/images/online-payment-icon.png"),"width":"40px","height":"auto","alt":""}})]),_c('div',{staticClass:"flex-fill py-2"},[_c('p',{staticClass:"font-PR text-start ps-3 fs-18 my-0"},[_vm._v(" "+_vm._s(_vm.$t(`checkout.pay_with_barion`))+" ")])]),_c('div',{staticClass:"checkbox flex-shrink py-2"},[_c('div',[_c('span')])])])])])]),_c('div',{staticClass:"w-100 mt-auto mx-auto maxw-600"},[_c('div',{staticClass:"w-100 d-flex flex-row align-items-center justify-content-between"},[_c('button',{staticClass:"btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18",on:{"click":_vm.checkout}},[_vm._v(" "+_vm._s(_vm.$t(`btn.checkout_now`))+" ")])])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }