<template>
  <div class="pb-4">
    <div>
      <p class="font-PM fs-22 mb-1 ps-1 pb-0">
        {{ $t(`popular_box.recommendations`) }}
      </p>
    </div>
    <div class="w-100">
      <swiper
        ref="mySwiper"
        class="swiper position-relative d-block px-3 py-2"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(item, index) in recommendedItems"
          :key="index"
          class="h-auto popular-slide"
        >
          <ProductCard :item="item" />
        </swiper-slide>

        <!-- <div
          class="swiper-button-prev popular-swiper-btn-prev"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next popular-swiper-btn-next"
          slot="button-next"
        ></div> -->
      </swiper>
    </div>
  </div>
</template>
<script>
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
const { Swiper, SwiperSlide, directive } = getAwesomeSwiper(SwiperClass);
import ProductCard from "./ProductCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "PopularBox",
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters(["getAdult"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    recommendedItems() {
      const elements = [];
      this.items.forEach((item) => {
        if (!item.hasAgeGate || this.getAdult) {
          elements.push(item);
        }
      });
      return elements;
    },
  },
  props: {
    items: Array,
  },
  data() {
    return {
      isAdult: null,
      swiperOption: {
        slidesPerView: "auto",
        autoHeight: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
<style lang="scss">
.popular-swiper-btn-prev {
  z-index: 99;
  position: absolute;
  display: block;
  top: calc(50% - 21px);
  left: 0px;
  width: 20px;
  height: 42px;
  cursor: pointer;
  background-image: url("~@/assets/images/prev.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 32px;
  &.swiper-button-disabled {
    opacity: 0.2 !important;
  }
}
.popular-swiper-btn-next {
  z-index: 99;
  position: absolute;
  display: block;
  top: calc(50% - 21px);
  right: 0px;
  width: 20px;
  height: 42px;
  cursor: pointer;
  background-image: url("~@/assets/images/next.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 32px;
  &.swiper-button-disabled {
    opacity: 0.2 !important;
  }
}

.popular-slide {
  width: 220px !important;
  margin-right: 50px;
}
</style>
