<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center pb-4 zi-100 position-fixed bg-white w-100 h-100vh"
  >
    <div class="mb-auto mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          <img src="@/assets/images/cart-on.svg" width="80" />
        </div>
        <div class="text-center color-text-secondary mt-3">
          {{ message }}
        </div>
        <div
          class="spinner-border spinner-border-xxl color-text-secondary p-4 mt-5 float-none d-inline-block"
          role="status"
        >
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Waiting",
  props: ["message"],
};
</script>
