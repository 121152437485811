import axios from "axios";
import store from "../store";
import router from "../router";

const apiService = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

apiService.interceptors.request.use(
  async (config) => {
    if (store.state?.tableSession?.token) {
      config.headers = {
        Authorization: `Bearer ${store.state.tableSession.token}`,
        Accept: `application/json`,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (process.env.NODE_ENV === "production") {
      router.push({ name: "Error" });
    }
    return error;
  }
);

export default apiService;
