<template>
  <div class="h-100 product-gallery-item">
    <img
      :src="getImgUrl(item.imageUrl)"
      width="100%"
      height="auto"
      class="rounded-4"
      :alt="name"
    />
  </div>
</template>

<script>
export default {
  name: "ProductGalleryItem",
  props: ["item", "name"],
  methods: {},
};
</script>
<style lang="scss">
.product-gallery-item {
  width: 250px;
}
</style>
