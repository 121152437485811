import apiService from "../services/apiService";

export default {
  post(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`error/report`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
};
