import apiService from "../services/apiService";

export default {
  getById(id) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`location/${id}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
};
