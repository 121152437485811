<template>
  <div class="product-item w-100 radius-12 my-3">
    <div
      class="bg-color-gray rounded-10 card-border overflow-hidden zi-2 position-relative"
    >
      <div
        @click="openItem(product)"
        class="btn btn-block m-0 d-flex flex-row w-100 py-0 px-0 box-shadow position-relative zi-99 align-items-center justify-content-start rounded-0 shadow-sm"
      >
        <div class="px-0 flex-fill minw-150 maxw-160">
          <img
            :alt="product.name"
            :src="getImgUrl(product.imgURL)"
            width="100%"
            height="auto"
            class="minw-160 maxw-160"
          />
        </div>
        <div class="content flex-shrink text-start ps-4 pr-1">
          <div>
            <h2
              class="color-text-primary hover-color-text-primary font-PR fs-20 mb-1 uppercased"
            >
              {{ product.name }}
            </h2>
          </div>
          <div>
            <h4
              class="color-text-secondary hover-color-text-secondary font-PR fs-14 mb-0"
            >
              {{ product.variation }}
            </h4>
          </div>
        </div>
        <div v-if="showRightInfo" class="font-PM minw-100">
          <div class="fs-15 font-PB">x {{ product.qty }}</div>
          <div class="fs-18 text-color-primary">
            {{ formatPrice(product.price) }}
          </div>
        </div>
      </div>
      <div v-if="showCartLine === true" class="cart-line w-100">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-center"
        >
          <div class="flex-shrink text-center qty px-3 minw-160">
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-decrese"
                  @click="decrease"
                >
                  -
                </button>
              </div>
              <div class="px-2 fs-16">{{ localQty }} {{ product.unit }}</div>
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-increase"
                  @click="increase"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex-fill text-center bg-color-dark-gray text-color-primary h-100 font-PM fs-15 price px-2 py-3 minw-80"
          >
            {{ totalPrice }}
          </div>
          <div
            class="flex-shrink text-center add-to-cart-btn h-100 py-2 px-3 fs-15 font-PR minw-120"
            @click="productOption(product.id, localQty)"
          >
            {{ $t("btn.to_cart") }}
          </div>
        </div>
      </div>
      <div v-if="showDeleteLine === true" class="cart-line w-100">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-center"
        >
          <div class="flex-shrink text-center qty px-3 minw-160">
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-decrese"
                  @click="decrease"
                >
                  -
                </button>
              </div>
              <div class="px-2 fs-16">{{ localQty }} {{ product.unit }}</div>
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-increase"
                  @click="increase"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex-fill text-center bg-color-dark-gray text-color-primary h-100 font-PM fs-15 price px-2 py-3 minw-80"
          >
            {{ totalPrice }}
          </div>
          <div
            class="flex-shrink text-center bg-color-dark-gray remove-from-cart-btn h-100 py-2 px-3 fs-15 font-PR"
            @click="removeItem"
          >
            <DeleteIcon />
          </div>
        </div>
      </div>
      <div v-if="showInfoLine === true" class="info-line w-100 card-border">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-center"
        >
          <div class="flex-fill text-center qty">
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <div class="px-2 py-2">{{ localQty }} {{ getCurrency }}</div>
            </div>
          </div>
          <div
            class="flex-shrink text-center bg-color-dark-gray text-color-primary minw-100 font-PM price px-2 py-2"
          >
            {{ formatPrice(product.price) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="product?.rejectComment" class="badge-container">
      <div class="badge-item">
        <div>!</div>
      </div>
    </div>
    <div
      v-if="product?.rejectComment || info?.options || product?.comment"
      class="bg-color-dark-gray options-box mx-auto px-4 py-3 text-secondary fs-14 lh-15 card-border"
    >
      <div>
        <div v-for="(options, i) in info.options" :key="i" class="">
          <div v-if="options.length > 0" class="pb-3">
            <div v-for="(option, j) in options" :key="j">
              <div class="d-flex flex-row align-items-center">
                <div
                  class="flex-fill"
                  :class="i == 'exeptBaseOptionInfo' ? 'line-through' : ''"
                >
                  <h5 class="mb-0">{{ option.name }}</h5>
                  <small>{{ option.description }}</small>
                </div>
                <div>
                  <p v-if="i == 'exeptBaseOptionInfo'">
                    - {{ option.price }} Ft
                  </p>
                  <p v-else>+ {{ option.price }} Ft</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="product.comment">
        <small>Comment:</small>
        <h5>{{ product.comment }}</h5>
      </div>
      <div v-if="product?.rejectComment" class="color-warning">
        {{ $t("orders.rejected_reason") }}
        <div>
          {{ product.rejectComment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeleteIcon from "./DeleteIcon.vue";
export default {
  name: "ProductItem",
  components: {
    DeleteIcon,
  },
  props: {
    showCartLine: {},
    showInfoLine: {},
    showDeleteLine: {},
    showSelectedOptionsBlock: {},
    showRightInfo: {
      default: false,
      type: Boolean,
    },
    product: {},
    isCart: {
      default: false,
      type: Boolean,
    },
    info: {
      type: Object,
      required: false,
    },
    index: {},
    showRejectedBadge: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      sheetIsOpen: false,
      localQty: 1,
    };
  },
  mounted() {
    if (this.isCart) {
      this.localQty = this.product.qty;
    }
  },
  computed: {
    ...mapGetters(["getCurrency"]),
    totalPrice: function () {
      const totalPrice = parseInt(this.product.price) * parseInt(this.localQty);
      return this.formatPrice(totalPrice);
    },
  },
  methods: {
    ...mapActions(["addCartItem"]),
    increase: function () {
      this.localQty += 1;
      const data = {
        index: this.index,
        item: {
          ...this.product,
          qty: this.localQty,
        },
      };
      this.$emit("change-item", data);
    },
    decrease: function () {
      if (this.localQty > 1) {
        this.localQty -= 1;
        const data = {
          index: this.index,
          item: {
            ...this.product,
            qty: this.localQty,
          },
        };
        this.$emit("change-item", data);
      }
    },
    openItem(item) {
      this.$router.push({ name: "Product", params: { id: item.id } });
    },
    removeItem: function () {
      this.$emit("remove-item", this.index);
    },
    productOption(productId, quantity) {
      const data = {
        productId,
        quantity,
      };
      this.$emit("open-product-options", data);
    },
  },
};
</script>

<style lang="scss">
.product-item {
  .cart-line {
    .qty {
      .btn-qty {
        padding: 0 0 3px 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--primary-color);
        border: 2px solid map_get($colors, "white");
        color: map_get($colors, "white");
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 0;
        vertical-align: middle;
      }
    }
    .price {
      font-style: normal;
      font-weight: bold;
      font-size: 22px !important;
      line-height: 18px;
      color: var(--primary-color);
      //padding: 0.7rem 0 !important;
      @media screen and (max-width: 420px) {
        font-size: 18px !important;
        line-height: 16px;
      }
    }
    .add-to-cart-btn {
      background-color: var(--primary-color);
      color: map_get($colors, "white");
      cursor: pointer !important;
      height: 50px !important;
      line-height: calc(50px - 1rem);
      vertical-align: middle;
    }
    .remove-from-cart-btn {
      color: map_get($colors, "white");
      cursor: pointer !important;
      height: 50px !important;
      line-height: calc(50px - 1rem);
      vertical-align: middle;
      cursor: pointer !important;
    }
  }
  .options-box {
    width: 90%;
    border-radius: 0 0 12px 12px;
  }
}
.badge {
  &-container {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
  }
  &-item {
    background-color: var(--danger-color);
    color: white;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 28px;
    font-size: 25px;
    font-weight: 500;
    border-radius: 100%;
    animation: pulse 2s infinite;
  }
}
.rounded-10 {
  border-radius: 10px;
}
</style>
