import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";
import globalMixin from "@/mixins/index.js";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.mixin(globalMixin);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// Global variables
Vue.prototype.$imgBaseUrl = process.env.VUE_APP_IMAGE_BASE_URL;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
