<template>
  <div
    id="cart-line"
    class="mb-0 mt-auto maxw-600 shadow-top radius-12 bg-white pb-2"
  >
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-center minh-90 pb-3"
    >
      <div class="flex-fill text-center color-white px-4">
        <button
          class="btn btn-block btn-monty w-100 px-2 py-3 color-white font-PR fs-15"
          @click="checkout"
        >
          {{ $t(`checkout.checkout`) }}
        </button>
      </div>
      <div class="flex-fill d-flex flex-column px-4">
        <div
          class="font-PR mb-0 d-inline-block fs-15 color-text-primary text-right"
        >
          {{ $t(`product.total_price`) }}
        </div>
        <div
          class="font-PM mb-0 d-inline-block fs-16 text-color-primary text-right"
        >
          {{ formatPrice(sumPrice) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutLine",
  props: ["sumPrice"],
  created() {},
  methods: {
    checkout() {
      this.$emit("send-checkout");
    },
  },
};
</script>

<style lang="scss">
#cart-line {
  position: fixed;
  width: 100%;
  bottom: 70px;
  z-index: 9;
}
</style>
