<template>
  <div>
    <swiper
      ref="productGallerySwiper"
      class="swiper position-relative d-block px-3 py-2"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(item, index) in gallery.images"
        :key="index"
        class="h-auto product-gallery-slide"
      >
        <ProductGalleryItem :item="item" :name="gallery.name" />
      </swiper-slide>
      <div
        v-show="hasMoreImage"
        class="swiper-button-prev product-gallery-button-prev"
        slot="button-prev"
      ></div>
      <div
        v-show="hasMoreImage"
        class="swiper-button-next product-gallery-button-next"
        slot="button-next"
      ></div>
    </swiper>
    <div class="w-100 px-4" v-show="hasMoreImage">
      <div class="product-gallery-pagination text-center w-100"></div>
    </div>
  </div>
</template>
<script>
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
const { Swiper, SwiperSlide, directive } = getAwesomeSwiper(SwiperClass);
import ProductGalleryItem from "./ProductGalleryItem.vue";
export default {
  name: "ProductGallery",
  props: ["gallery"],
  components: {
    ProductGalleryItem,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    swiper() {
      return this.$refs.productGallerySwiper.$swiper;
    },
    hasMoreImage() {
      if (this.gallery !== null && this.gallery.images != undefined) {
        return this.gallery.images.length > 1 ? true : false;
      }
      return false;
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        autoHeight: false,
        pagination: {
          el: ".product-gallery-pagination",
        },
        navigation: {
          nextEl: ".product-gallery-button-next",
          prevEl: ".product-gallery-button-prev",
        },
      },
    };
  },
};
</script>
<style lang="scss">
.product-gallery-button-prev {
  z-index: 99;
  position: absolute;
  display: block;
  top: calc(50% - 21px);
  left: 0px;
  width: 20px;
  height: 42px;
  cursor: pointer;
  background-image: url("~@/assets/images/prev.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 32px;
  &.swiper-button-disabled {
    opacity: 0.2 !important;
  }
}
.product-gallery-button-next {
  z-index: 99;
  position: absolute;
  display: block;
  top: calc(50% - 21px);
  right: 0px;
  width: 20px;
  height: 42px;
  cursor: pointer;
  background-image: url("~@/assets/images/next.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 32px;
  &.swiper-button-disabled {
    opacity: 0.2 !important;
  }
}
// .product-gallery-slide {
// }
</style>
