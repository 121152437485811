<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
  >
    <ProductOptions
      v-if="openOptions"
      :id="productId"
      :quantity="productQuantity"
      @close-product-options="closeProductOptions"
    />
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="categoryName"
      />
      <transition name="fade" mode="out-in" appear>
        <div class="overflow-auto">
          <div v-for="(item, index) in products" :key="index">
            <ProductItem
              v-if="isVisible(item.hasAgeGate)"
              :showCartLine="true"
              :showInfoLine="false"
              :product="item"
              @open-product-options="openProductOptions"
            />
          </div>
        </div>
      </transition>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar :current="'category'" />
    </div>
  </div>
</template>

<script>
import ProductRepository from "../repositories/ProductRepository";
import ProductCategoriesRepository from "../repositories/ProductCategoriesRepository";

import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import ProductItem from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import ProductOptions from "../components/ProductOptions.vue";

export default {
  name: "Products",
  components: {
    ProductOptions,
    HeaderNav,
    ProductItem,
    NavBar,
  },
  data() {
    return {
      openOptions: false,
      productId: 0,
      productQuantity: 0,
      products: [],
      categoryName: "",
      isAdult: null,
    };
  },
  computed: {
    ...mapGetters(["getLocationId", "getAdult"]),
  },
  mounted() {
    if (this.$route.params.id != null || this.$route.params.id !== undefined) {
      this.getProducts(this.$route.params.id);
      this.getCategory();
    }
  },
  methods: {
    async getProducts(id) {
      this.products = await ProductRepository.getByCategoryId(id);
    },
    async getCategory() {
      const data = {
        categoryId: parseInt(this.$route.params.id),
        locationId: this.getLocationId,
      };
      this.category = await ProductCategoriesRepository.getCategory(data);
      this.categoryName = this.category?.name;
    },
    openProductOptions(data) {
      this.productQuantity = data.quantity;
      this.productId = data.productId;
      this.openOptions = true;
    },
    closeProductOptions() {
      this.openOptions = false;
    },
    isVisible(hasAgeGate) {
      if (hasAgeGate === true && !this.getAdult) {
        return false;
      }
      return true;
    },
  },
};
</script>
