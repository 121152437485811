import Vue from "vue";
import VueRouter from "vue-router";

import Loading from "../views/Loading.vue";
import LanguageSelector from "../views/LanguageSelector.vue";
import Tutorial from "../views/Tutorial.vue";
// import Verification from "../views/Verification.vue";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Category from "../views/Category.vue";
import Products from "../views/Products.vue";
import Product from "../views/Product.vue";
import Cart from "../views/Cart.vue";
import Orders from "../views/Orders.vue";
import Error from "../views/Error.vue";
import OrderCompleted from "../views/OrderCompleted.vue";
import CheckoutCompleted from "../views/CheckoutCompleted.vue";
import Checkout from "../views/Checkout.vue";
import CheckoutPayment from "../views/CheckoutPayment.vue";
import CheckoutGuestPayment from "../views/CheckoutGuestPayment.vue";
import CheckoutWaiting from "../views/CheckoutWaiting.vue";
import CheckoutGuestWaiting from "../views/CheckoutGuestWaiting.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:hash?",
    name: "Loading",
    component: Loading,
    props: false,
  },
  {
    path: "/app/language",
    name: "LanguageSelector",
    component: LanguageSelector,
  },
  {
    path: "/app/tutorial",
    name: "Tutorial",
    component: Tutorial,
  },
  // {
  //   path: "/app/verification",
  //   name: "Verification",
  //   component: Verification,
  // },
  {
    path: "/app/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/app/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/app/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/app/products/:id",
    name: "Products",
    component: Products,
    props: false,
  },
  {
    path: "/app/product/:id",
    name: "Product",
    component: Product,
    props: false,
  },
  {
    path: "/app/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/app/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/app/order-completed",
    name: "OrderCompleted",
    component: OrderCompleted,
  },
  {
    path: "/app/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/app/checkout/payment",
    name: "CheckoutPayment",
    component: CheckoutPayment,
  },
  {
    path: "/app/checkout/payment/guest",
    name: "CheckoutGuestPayment",
    component: CheckoutGuestPayment,
  },

  {
    path: "/app/checkout/completed",
    name: "CheckoutCompleted",
    component: CheckoutCompleted,
  },
  {
    path: "/app/checkout/waiting",
    name: "CheckoutWaiting",
    component: CheckoutWaiting,
  },
  {
    path: "/app/checkout/waiting/guest/:order?",
    name: "CheckoutGuestWaiting",
    component: CheckoutGuestWaiting,
    props: false,
  },
  {
    path: "/app/error",
    name: "Error",
    component: Error,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
