<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-4"
  >
    <div class="mb-auto mt-auto" v-if="!notified">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          !
        </div>
        <div class="fs-48">
          {{ $t(`error.ouch`) }}
        </div>
        <div
          class="text-center color-text-secondary mt-3"
          v-html="$t(`error.something_wrong`)"
        ></div>
      </div>
    </div>
    <div v-else class="mb-auto mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          <img
            src="@/assets/images/waiting-icon.svg"
            width="40"
            height="auto"
            alt=""
            class="hourglass-rotation"
          />
        </div>
        <div class="fs-48">
          {{ $t(`error.please_wait`) }}
        </div>
        <div class="text-center color-text-secondary mt-3">
          {{ $t(`error.waiter_coming`) }}
        </div>
      </div>
    </div>
    <div class="py-4 row" v-if="!notified">
      <div class="col-12">
        <button
          v-if="hasHas"
          class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
          slot="button-next"
          @click="callWaiter"
        >
          {{ $t("btn.call_waiter") }}
        </button>
      </div>
      <div class="col-12">
        <button
          v-if="hasHas"
          id="skip"
          class="btn btn-link font-PR fs-18 w-100 color-text-primary clear-link mt-3 py-3"
          @click="back"
        >
          {{ $t("btn.back") }}
        </button>
      </div>
    </div>
    <div class="py-4 row" v-else>
      <div class="col-12">
        <button
          class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
          slot="button-next"
          @click="goHome"
        >
          {{ $t("btn.go_home") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorRepository from "../repositories/ErrorRepository";
import TableEventUser from "../repositories/TableEventUser";
import { mapGetters } from "vuex";

export default {
  name: "Error",
  computed: {
    ...mapGetters(["getTableSessionObject", "getLocationObject", "getCart"]),
  },
  mounted() {
    this.errorReporting();

    if (this.$store.state?.tableSession) {
      this.hasHas = true;
    }
  },
  data() {
    return {
      notified: false,
      hasHas: false,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    async callWaiter() {
      const response = await TableEventUser.call();
      if (response.success) {
        this.notified = true;
      }
    },
    async errorReporting() {
      const data = {
        location: this.getLocationObject,
        tableSession: this.getTableSessionObject,
        cart: this.getCart,
      };
      //TODO: api not created in backend side
      await ErrorRepository.post(data);
    },
  },
};
</script>
