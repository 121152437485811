<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
  >
    <ProductOptions
      v-if="openOptions"
      :id="productId"
      :quantity="productQuantity"
      @close-product-options="closeProductOptions"
    />
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t('search.title')"
      />
      <transition name="fade" mode="out-in" appear>
        <div>
          <!-- Search  -->
          <SearchBox v-model="keyword" v-on:searchAction="search" />
          <!-- Separator -->
          <hr class="mb-1" />
        </div>
      </transition>
    </div>
    <transition name="fade" mode="out-in" appear>
      <div
        class="mx-auto px-3 my-auto w-100 maxw-600 bg-color-white position-relative"
      >
        <div v-if="countItems > 0">
          <div v-for="item in items" :key="item.id">
            <ProductItem
              v-if="isVisible(item.hasAgeGate)"
              :showCartLine="true"
              :showInfoLine="false"
              :product="item"
              @open-product-options="openProductOptions"
            />
          </div>
        </div>
        <div
          v-else
          class="mx-auto px-3 w-100 maxw-600 my-auto bg-color-white position-relative"
        >
          <div class="my-auto pt-5">
            <div class="h-100 text-center align-items-center">
              <img
                src="@/assets/images/search.svg"
                width="30%"
                height="auto"
                alt="list"
                class="maxw-90"
              />
            </div>
            <div>
              <p
                class="font-PR text-center color-text-secondary fs-20 pt-5 mb-0"
              >
                {{ $t(`search.please_enter_string`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar :current="'search'" />
    </div>
  </div>
</template>

<script>
import ProductSearchRepository from "../repositories/ProductSearchRepository";

import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import SearchBox from "../components/SearchBox.vue";
import ProductItem from "../components/ProductItem.vue";
import ProductOptions from "../components/ProductOptions.vue";
import { mapGetters } from "vuex";
export default {
  name: "Search",
  components: {
    HeaderNav,
    SearchBox,
    ProductItem,
    NavBar,
    ProductOptions,
  },
  data() {
    return {
      openOptions: false,
      keyword: null,
      items: {},
      productId: 0,
      productQuantity: 0,
      isAdult: null,
    };
  },
  computed: {
    ...mapGetters(["getLocationId", "getAdult"]),
    countItems: function () {
      return this.items?.length;
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("name")) {
      this.searchData(urlParams.get("name"));
      this.keyword = urlParams.get("name");
    }
  },
  mounted() {},
  methods: {
    search: function () {
      this.searchData(this.keyword);
      this.$router
        .replace({
          name: "Search",
          query: { name: this.keyword },
        })
        .catch(() => {});
    },
    searchData: async function (keyword) {
      this.items = await ProductSearchRepository.searchByKeyword(
        this.getLocationId,
        keyword
      );
    },
    openProductOptions(data) {
      this.productQuantity = data.quantity;
      this.productId = data.productId;
      this.openOptions = true;
    },
    closeProductOptions() {
      this.openOptions = false;
    },
    isVisible(hasAgeGate) {
      if (hasAgeGate === true && !this.getAdult) {
        return false;
      }
      return true;
    },
  },
};
</script>
