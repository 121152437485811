<template>
  <div
    class="d-flex flex-column align-items-center justify-content-start pb-0 zi-100 position-fixed bg-white w-100 h-100"
  >
    <div
      class="product-options overflow-auto remove-scrollbar w-100 h-100 d-flex flex-column position-relative"
    >
      <div class="mx-auto px-3 pt-2 pb-3 w-100 maxw-600">
        <HeaderNav
          :backButtonBg="'bg-color-gray'"
          :backAction="closeProductOptions"
          :title="$t('product.select_options')"
        />
      </div>
      <div class="pb-5">
        <transition name="fade" mode="out-in" appear>
          <div
            class="w-100 minh-100 d-flex flex-column mt-n3 mx-auto maxw-600 mt-2 px-4 content-section"
          >
            <div
              class="flex-shrink d-inline-block qty px-1 pb-4 mx-auto w-100 position-relative"
            >
              <div class="fs-14 text-secondary headline">
                {{ $t("product.quantity") }}
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-between option-inner"
              >
                <div>
                  <button
                    class="btn btn-block btn-qty shadow"
                    @click="decrease"
                  >
                    -
                  </button>
                </div>
                <div class="px-4 minw-80 fs-20">
                  {{ qty }} {{ product.unit }}
                </div>
                <div>
                  <button
                    class="btn btn-block btn-qty shadow"
                    @click="increase"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="w-100 h-100 flex-fill mt-0 mx-auto maxw-600 pb-2">
              <div v-if="options.baseOptions && options.baseOptions.length > 0">
                <h5 class="pt-1 pb-0 mb-0 px-2">
                  {{ options.baseOptions[0].baseOptionInfo.name }}
                </h5>
                <p class="px-2 mb-3 lh-17">
                  <small>
                    {{ options.baseOptions[0].baseOptionInfo.description }}
                  </small>
                </p>
              </div>
              <div
                class="pb-3"
                v-for="(item, index) in options.baseOptions"
                :key="item.id"
              >
                <ProductOptionItem v-model="options.baseOptions[index]" />
              </div>
            </div>
            <div
              v-for="item in options.extraOptionsCategories"
              :key="`${item.id}_${options.id}`"
              class="w-100 h-100 flex-fill mt-0 mx-auto maxw-600 pb-0"
            >
              <div>
                <h5 class="pt-0 pb-0 mb-0 px-2">
                  {{ item.extraOptionCategoryInfo.name }}
                </h5>
                <p class="px-2 mb-3 lh-17">
                  <small>
                    {{ item.extraOptionCategoryInfo.description }}
                  </small>
                </p>
              </div>
              <div
                class="pb-3"
                v-for="(option, optionIndex) in item.options"
                :key="option.id"
              >
                <ProductOptionItem v-model="item.options[optionIndex]" />
              </div>
            </div>
            <div class="w-100 h-100 flex-fill mt-0 mx-auto maxw-600 pb-0">
              <div>
                <h5 class="pt-2 pb-0 mb-0 px-2">
                  {{ $t("product.comment") }}
                </h5>
              </div>
              <div class="form-group pt-2 px-1">
                <textarea
                  class="form-control rounded-4 p-3"
                  v-model="comment"
                  rows="5"
                  :placeholder="$t('product.comment_placeholder')"
                ></textarea>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="w-100 mt-auto mx-auto maxw-600 pb-5 position-relative px-3">
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-between options-summary maxw-600 py-3 bg-color-white shadow-bottom card-border"
        >
          <div class="ps-3">
            <h3 class="font-PM fs-18 mb-0 color-text-primary text-start">
              {{ $t("product.total_price") }}:
            </h3>
            <h3 class="font-PM fs-20 mb-0 text-color-primary text-start">
              {{ sumProductPrice }}
            </h3>
          </div>
          <div class="pe-3">
            <button
              @click="addToCart"
              class="btn btn-block btn-monty w-100 py-2 px-5 color-white font-PR fs-18"
            >
              {{ $t("btn.to_cart") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import HeaderNav from "./HeaderNav.vue";
import ProductOptionItem from "./ProductOptionItem.vue";
import ProductRepository from "../repositories/ProductRepository";

export default {
  name: "ProductOptions",
  components: {
    HeaderNav,
    ProductOptionItem,
  },
  computed: {
    checkOptions() {
      const ids = {
        exceptBaseOptionIds: [],
        baseOptionIds: [],
        extraOptionIds: [],
        extraProductIds: [],
      };
      if (this.options.baseOptions) {
        this.options.baseOptions.forEach(function (option) {
          if (option.state) {
            ids.baseOptionIds.push(option.productOptionId);
          } else {
            ids.exceptBaseOptionIds.push(option.productOptionId);
          }
        });
      }
      if (this.options.extraOptionsCategories) {
        this.options.extraOptionsCategories.forEach(function (category) {
          category.options.forEach(function (option) {
            if (option.state) {
              ids.extraOptionIds.push(option.id);
            }
          });
        });
      }
      return ids;
    },
    substractFee() {
      var fee = 0;
      if (this.options.baseOptions) {
        this.options.baseOptions.forEach(function (option) {
          if (!option.state) {
            fee += option.substactPrice ?? 0;
          }
        });
      }
      return fee * -1;
    },
    extraFee() {
      var fee = 0;
      if (this.options.extraOptionsCategories) {
        this.options.extraOptionsCategories.forEach(function (category) {
          category.options.forEach(function (option) {
            if (option.state) {
              fee += option.price ?? 0;
            }
          });
        });
      }
      return fee;
    },
    productPrice() {
      return this.product.price + this.substractFee + this.extraFee;
    },
    sumProductPrice() {
      const price = this.qty * this.productPrice;
      return this.formatPrice(price);
    },
  },
  props: ["id", "quantity"],
  data() {
    return {
      qty: this.quantity,
      comment: "",
      product: [],
      options: [],
    };
  },
  created() {
    this.getProduct(this.id);
    this.getOptions(this.id);
  },
  methods: {
    ...mapActions(["addCartItem"]),
    async getProduct(id) {
      this.product = await ProductRepository.getById(id);
    },
    async getOptions(id) {
      this.options = await ProductRepository.getOptionsById(id);
    },
    async addToCart() {
      const data = {
        ...this.product,
        ...this.checkOptions,
        price: this.productPrice,
        qty: this.qty,
        comment: this.comment,
      };
      //console.log(data);
      await this.addCartItem(data);
      this.closeProductOptions();
    },
    increase: function () {
      this.qty += 1;
    },
    decrease: function () {
      if (this.qty > 1) {
        this.qty -= 1;
      }
    },
    closeProductOptions: function () {
      this.$emit("close-product-options", true);
    },
  },
};
</script>

<style lang="scss">
.product-options {
  .option-inner {
    border: 1px solid map_get($colors, "light-gray");
    border-radius: 15px;
    padding: 15px;
  }
  .headline {
    position: absolute;
    top: -12px;
    left: 22px;
    background: white;
    padding: 0 10px;
  }
  .qty {
    .btn-qty {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 0;
      padding: 0 0 5px 0;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
    }
  }
}
.options-summary {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 600px;
  margin-left: -1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
