import apiService from "../services/apiService";
import store from "../store";

export default {
  call() {
    return new Promise((resolve, reject) => {
      apiService
        .get(`event/u/call`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  checkout(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`event/u/checkout`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  prepaid(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`event/u/prepaid`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  order(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`event/u/order`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  orders() {
    return new Promise((resolve, reject) => {
      apiService
        .get(`event/u/orders`)
        .then((response) => resolve(this.buildOrdersData(response.data)))
        .catch((error) => reject(error));
    });
  },
  async getRejectedOrderById(id) {
    const allOrders = await this.orders();
    return allOrders.find((item) => item.id === parseInt(id));
  },
  buildOrdersData(response) {
    const orders = [];
    if (response?.data?.length > 0) {
      response.data.forEach(function (order) {
        const acceptedElements = [];
        order?.acceptedOrderItems?.forEach(function (orderItems) {
          const productInfo = orderItems?.product?.productInfos?.find(
            ({ language }) => language === store.state.lang
          );
          const product = {
            id: orderItems?.productId,
            price: orderItems?.productSumPrice,
            qty: orderItems?.productQuantity,
            comment: orderItems?.comment,
            imgURL: orderItems?.product?.productImages?.[0].imageUrl,
            name: productInfo?.name,
            variation: productInfo?.variation,
          };
          acceptedElements.push(product);
        });

        const rejectedItems = [];
        order?.rejectedOrderItems?.forEach(function (orderItems) {
          const productInfo = orderItems?.product?.productInfos?.find(
            ({ language }) => language === store.state.lang
          );
          const product = {
            id: orderItems?.productId,
            price: orderItems?.productSumPrice,
            qty: orderItems?.productQuantity,
            comment: orderItems?.comment,
            imgURL: orderItems?.product?.productImages?.[0].imageUrl,
            name: productInfo?.name,
            variation: productInfo?.variation,
            rejectComment: orderItems?.rejectComment,
          };
          rejectedItems.push(product);
        });
        const data = {
          id: order.id,
          status: order.status,
          createdAt: order.incomingAt,
          acceptedItems: [...acceptedElements],
          rejectedItems: [...rejectedItems],
        };
        orders.push(data);
      });
    }

    orders.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return orders;
  },
};
