<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-4"
  >
    <div class="mb-auto mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          <img src="@/assets/images/done-icon.svg" width="80" />
        </div>
        <div class="text-center color-text-secondary mt-3">
          {{ $t(`order.completed`) }}
        </div>
      </div>
    </div>
    <div class="py-4 row">
      <div class="col-12">
        <button
          class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
          slot="button-next"
          @click="goHome"
        >
          {{ $t("btn.go_home") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderCompleted",
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
