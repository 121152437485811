<template>
  <div
    class="w-100 px-4 d-flex flex-column justify-content-around"
    :style="mobileFullScreen()"
  >
    <h1 class="fs-38 text-center font-PL">
      {{ $t(`language.choose_your_language`) }}
    </h1>
    <div class="language-selector">
      <button
        v-for="language in languages"
        @click="selectLang(language.value)"
        :key="language.id"
        class="d-flex justify-content-between align-items-center btn btn-block bg-color-gray w-100 color-white font-PR fs-20 py-3 mb-4"
      >
        <div class="d-flex">
          <img
            style="width: 45px"
            :src="imgUrl(language.value)"
            :alt="language.value"
          />
          <div class="ms-3 text-black">
            {{ language.name }}
          </div>
        </div>
        <div
          class="d-flex language-selector-circle"
          v-bind:class="{ active: language.value === lang }"
        ></div>
      </button>
    </div>
    <button
      :disabled="isSubmitDisabled"
      class="swiper-button-next btn btn-monty px-5 py-2 color-white font-PR fs-18"
      @click="next()"
    >
      {{ $t("btn.next") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LanguageSelector",
  data() {
    return {
      languages: [],
      lang: "",
    };
  },
  computed: {
    ...mapGetters([
      "getLang",
      "getTableSessionObject",
      "getSelectableLanguages",
    ]),
    isSubmitDisabled: function () {
      return this.getLang === null;
    },
  },
  created() {
    this.languages = this.getSelectableLanguages;
  },
  methods: {
    imgUrl(lang) {
      return `/img/${lang}.svg`;
    },
    selectLang(lang) {
      this.$i18n.locale = lang;
      this.lang = lang;
      this.$store.dispatch("setLang", lang);
    },
    next() {
      if (
        this.getTableSessionObject.isTable == false &&
        this.getTableSessionObject.owner == "partner"
      ) {
        this.$router.push({ name: "Home" });
        return;
      }
      this.$router.push({ name: "Tutorial" });
    },
  },
};
</script>
