<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
    :class="[items.length > 0 ? 'pb-170px' : '']"
  >
    <Waiting v-if="waiting" :message="$t('waiting.order_sending')" />
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t('cart.title')"
      />
    </div>
    <transition name="fade" mode="out-in" appear>
      <div
        v-if="items.length > 0"
        class="mx-auto px-3 mt-0 h-auto w-100 maxw-600 bg-color-white position-relative"
      >
        <div class="d-flex h-100 flex-column w-100 align-items-center">
          <div class="w-100">
            <div v-for="(item, index) in items" :key="index" class="w-100">
              <ProductItem
                v-if="renderComponent"
                :showCartLine="false"
                :showInfoLine="false"
                :showDeleteLine="true"
                :showSelectedOptionsBlock="true"
                :index="index"
                :product="item"
                :info="getInfo(item.id)"
                :isCart="true"
                :showRejectedBadge="true"
                @remove-item="removeItem"
                @change-item="changeItem"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mx-auto px-3 my-auto w-100 maxw-600 bg-color-white position-relative"
      >
        <div class="my-auto">
          <div class="h-100 text-center align-items-center">
            <img
              src="@/assets/images/cart.svg"
              width="30%"
              height="auto"
              alt=""
              class="maxw-100"
            />
          </div>
          <div>
            <p class="font-PR text-center color-text-secondary fs-20 pt-5 mb-0">
              {{ $t(`cart.currently_empty`) }}
            </p>
          </div>
        </div>
      </div>
    </transition>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <CartLine v-if="items.length > 0" @send-order="sendOrder" />
      <!-- NavBar -->
      <NavBar :current="'cart'" />
    </div>
  </div>
</template>

<script>
import CartLine from "../components/CartLine.vue";
import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import ProductItem from "../components/ProductItem.vue";
import Waiting from "../components/Waiting.vue";
import { mapGetters, mapState } from "vuex";
import store from "../store";
import TableEventUser from "../repositories/TableEventUser";
import Product from "../repositories/ProductRepository";
//getOptionInfo
export default {
  name: "Cart",
  components: {
    HeaderNav,
    ProductItem,
    NavBar,
    CartLine,
    Waiting,
  },
  data() {
    return {
      waiting: false,
      renderComponent: true,
      infos: [],
    };
  },
  computed: {
    ...mapGetters(["getOrder", "getLocationId", "getIsKiosk", "getIsGuest"]),
    ...mapState(["cart"]),
    items() {
      return this.cart ?? [];
    },
  },
  async mounted() {
    const products = [];
    if (this.cart) {
      this.cart.forEach(function (item) {
        products.push({
          productId: item.id,
          exceptBaseOptionIds: item.exceptBaseOptionIds,
          extraOptionIds: item.extraOptionIds,
          extraProductIds: item.extraProductIds,
        });
      });
    }
    this.infos = await Product.getOptionInfo({
      locationId: this.getLocationId,
      language: store.state.lang,
      products: products,
    });
  },
  methods: {
    getInfo(id) {
      if (this.infos) {
        const productOptionInfo = this.infos.find(
          ({ productId }) => productId === id
        );
        return productOptionInfo;
      }
      return {};
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async removeItem(index) {
      await this.$store.dispatch("removeCartItem", index);
      this.forceRerender();
    },
    async changeItem(data) {
      await this.$store.dispatch("changeCartItem", data);
    },
    async sendOrder(value) {
      this.waiting = value;
      setTimeout(
        async function () {
          if (this.getIsKiosk || this.getIsGuest) {
            this.$router.push({ name: "CheckoutGuestPayment" });
          } else {
            let items = this.cart.map((item) => ({
              productId: item.id,
              productOptionIds: item.optionIds,
              productQuantity: item.qty,
              comment: item.comment,
              exceptBaseOptionIds: item.exceptBaseOptionIds,
              baseOptionIds: item.baseOptionIds,
              extraOptionIds: item.extraOptionIds,
              extraProductIds: item.extraProductIds,
            }));
            const data = {
              eventValue: this.getOrder.type,
              eventItems: [...items],
            };
            const order = await TableEventUser.order(data);
            if (order.success) {
              await this.$store.dispatch("resetCart");
              this.$router.push({ name: "OrderCompleted" });
            } else {
              this.$router.replace({ name: "Error" });
            }
          }
        }.bind(this),
        2000
      );
    },
  },
};
</script>
