<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
  >
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t('product.categories')"
      />

      <transition name="fade" mode="out-in" appear>
        <div class="row">
          <div
            class="col-6 my-2 py-1"
            v-for="(item, index) in categories"
            :key="index"
          >
            <CategoryItem :item="item" />
          </div>
        </div>
      </transition>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar :current="'category'" />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import CategoryItem from "../components/CategoryItem.vue";

import ProductCategoriesRepository from "../repositories/ProductCategoriesRepository";
import { mapGetters } from "vuex";

export default {
  name: "Category",
  data() {
    return {
      categories: [],
    };
  },
  components: {
    HeaderNav,
    CategoryItem,
    NavBar,
  },
  computed: {
    ...mapGetters(["getLocationId"]),
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = await ProductCategoriesRepository.getByLocationId(
        this.getLocationId
      );
    },
  },
};
</script>
