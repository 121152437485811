import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getCurrency"]),
  },
  methods: {
    mobileFullScreen() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      return "min-height: calc(var(--vh, 1vh) * 100)";
    },
    getImgUrl(img) {
      if (img) {
        return `${this.$imgBaseUrl}${img}`;
      }
    },
    formatPrice(price) {
      if (price && this.getCurrency === "Ft") {
        if (typeof price === "number") {
          price = price.toString();
        }
        let reversePrice = price.split("").reverse().join(""); // format to string and reverse string
        let addSpace = reversePrice.match(/.{1,3}/g).join(" "); // Add space after every 3rd element
        let reverseBack = addSpace.split("").reverse().join(""); // Reverse back
        return `${reverseBack} ${this.getCurrency}`;
      }
    },
  },
};
