<template lang="">
  <svg
    class="delete-icon"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentcolor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.397933 17.3438C0.00738152 17.7343 0.00738159 18.3675 0.397933 18.758L1.95973 20.3195C2.35028 20.71 2.98349 20.71 3.37405 20.3195L10.1071 13.5874L16.8403 20.3195C17.2308 20.71 17.864 20.71 18.2546 20.3195L19.8164 18.758C20.2069 18.3675 20.2069 17.7343 19.8164 17.3438L13.0833 10.6117L19.8164 3.87947C20.207 3.48897 20.207 2.85585 19.8164 2.46536L18.2546 0.903774C17.8641 0.513277 17.2309 0.513276 16.8403 0.903773L10.1071 7.63598L3.37401 0.903786C2.98346 0.513289 2.35025 0.513289 1.95969 0.903786L0.397895 2.46537C0.00734292 2.85586 0.0073422 3.48898 0.397894 3.87948L7.13103 10.6117L0.397933 17.3438Z"
      fill="currentcolor"
    />
  </svg>
</template>
<script>
export default {
  name: "delete-icon",
};
</script>
<style lang="scss">
.delete-icon {
  color: var(--danger-color);
  margin-top: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  &:hover {
    color: var(--primary-color);
  }
}
</style>
