<template>
  <div>
    <vue-bottom-sheet
      ref="actionSheet"
      :background-scrollable="false"
      :background-clickable="false"
      max-width="600px"
      max-height="450px"
      @closed="element = false"
    >
      <div class="px-4 pb-4 pt-2">
        <h1 class="font-PR text-center fs-24 pb-3">
          {{ $t(`order.order_settings`) }}
        </h1>

        <div class="my-2">
          <button
            class="btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center"
            @click="orderType = ORDER_TYPE.EAT_IN"
            :class="orderType === ORDER_TYPE.EAT_IN ? 'active' : ''"
          >
            <div class="flex-fill py-2">
              <p class="font-PR text-start ps-3 fs-18 my-0">
                {{ $t(`order.eat_here`) }}
              </p>
            </div>
            <div class="checkbox flex-shrink py-2">
              <div><span></span></div>
            </div>
          </button>
        </div>

        <div class="my-2">
          <button
            class="btn w-100 payment-method bg-color-gray radius-12 d-flex flex-row align-items-center"
            @click="orderType = ORDER_TYPE.TAKE_AWAY"
            :class="orderType === ORDER_TYPE.TAKE_AWAY ? 'active' : ''"
          >
            <div class="flex-fill py-2">
              <p class="font-PR text-start ps-3 fs-18 my-0">
                {{ $t(`order.take_away`) }}
              </p>
            </div>
            <div class="checkbox flex-shrink py-2">
              <div><span></span></div>
            </div>
          </button>
        </div>

        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="$emit('sendOrder', orderType)"
          >
            {{ $t("btn.next") }}
          </button>
        </div>

        <div class="text-center">
          <button
            class="btn btn-link font-PR fs-18 color-text-primary clear-link text-center"
            @click="close"
          >
            {{ $t("btn.cancel") }}
          </button>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { ORDER_TYPE } from "@/config/variables";
export default {
  name: "OrderSettingsSheet",
  props: ["value", "product"],
  components: {
    VueBottomSheet,
  },
  data() {
    return {
      orderType: ORDER_TYPE.EAT_IN,
    };
  },
  computed: {
    ORDER_TYPE() {
      return ORDER_TYPE;
    },
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    element: function () {
      if (this.element === true) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    open() {
      this.element = true;
      this.$refs.actionSheet.open();
    },
    close() {
      this.element = false;
      this.$refs.actionSheet.close();
    },
  },
};
</script>
<style lang="scss">
.bottom-sheet__card {
  height: 100vh !important;
  .bottom-sheet__content {
    height: 100% !important;
  }
}
</style>
