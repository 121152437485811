import apiService from "../services/apiService";
import store from "../store";

export default {
  getByLocationId(locationId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`productCategories/product/category/${locationId}`)
        .then((response) =>
          resolve(this.buildDataForGetByLocationId(response.data))
        )
        .catch((error) => reject(error));
    });
  },
  buildDataForGetByLocationId(items) {
    const elements = [];
    if (items?.data && items.data.length > 0) {
      items.data.forEach(function (item) {
        const categoryInfo = item?.productCategoryInfos.find(
          ({ lang }) => lang === store.state.lang
        );
        const category = {
          id: item.id,
          coverImageUrl: item.coverImageUrl,
          hasAgeGate: item.hasAgeGate,
          name: categoryInfo?.name,
          description: categoryInfo?.description,
        };
        elements.push(category);
      });
    }
    return elements;
  },
  async getCategory(data) {
    const categories = await this.getByLocationId(data.locationId);
    if (categories.length > 0) {
      return categories.find(({ id }) => id === data.categoryId);
    }
    return [];
  },
};
