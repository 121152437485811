<template>
  <div
    id="page"
    class="d-flex w-100 bg-color-white flex-column align-items-start justify-content-start"
  >
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 d-flex flex-column align-items-center bg-color-white position-relative"
      :class="[orders.length > 0 ? 'h-auto' : 'h-100']"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t(`orders.title`)"
      />
      <transition name="fade" mode="out-in" appear>
        <div v-if="orders.length > 0" class="w-100 px-2 pt-3">
          <div v-for="order in orders" :key="order.id">
            <div class="fs-18 font-PM">
              #{{ order.id }}
              -
              <span>
                {{ orderDate(order.createdAt) }}
              </span>
              -
              <span class="text-color-primary font-PR">
                {{ order.status }}
              </span>
            </div>
            <div
              v-for="(item, index) in order.acceptedItems"
              :key="'acceptedItems-' + index"
            >
              <ProductItem
                :showCartLine="false"
                :showSelectedOptionsBlock="true"
                :showRightInfo="true"
                :product="item"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="mx-auto px-3 w-100 maxw-600 my-auto bg-color-white position-relative"
        >
          <div class="my-auto">
            <div class="h-100 text-center align-items-center">
              <img
                src="@/assets/images/list-icon.svg"
                width="30%"
                height="auto"
                alt="list"
                class="maxw-100"
              />
            </div>
            <div>
              <p
                class="font-PR text-center color-text-secondary fs-20 pt-5 mb-0"
              >
                {{ $t(`orders.no_orders`) }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar current="" />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import ProductItem from "../components/ProductItem.vue";
import TableEventUser from "../repositories/TableEventUser";
import { ORDER_EVENT_STATUS } from "@/config/variables";

export default {
  name: "Orders",
  props: ["category"],
  components: {
    HeaderNav,
    ProductItem,
    NavBar,
  },
  data() {
    return {
      orders: [],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      const response = await TableEventUser.orders();
      console.log(response);
      this.orders = response.filter(
        (item) => item.status !== ORDER_EVENT_STATUS.REJECTED
      );
    },
    orderDate(createdAt) {
      const date = new Date(createdAt);
      // const year = date.getFullYear();
      // const month = ("0" + (date.getMonth() + 1)).slice(-2);
      // const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return /*year + "-" + month + "-" + day + " " + */ hours + ":" + minutes;
    },
  },
};
</script>
