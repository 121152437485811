<template>
  <div>
    <vue-bottom-sheet
      ref="actionSheet"
      :background-scrollable="false"
      :background-clickable="false"
      max-width="600px"
      max-height="350px"
      @closed="element = false"
    >
      <div class="px-4 pb-4 pt-2" v-if="sheetState === 0">
        <h1 class="font-PR fs-20 pb-3">
          {{ $t(`orders.rejected`) }}
        </h1>

        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="actions(CLIENT_EVENT.EDIT_ORDER)"
          >
            {{ $t(`btn.order_edit`) }}
          </button>
        </div>

        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="actions(CLIENT_EVENT.CALL_STAFF)"
          >
            {{ $t(`btn.call_waiter`) }}
          </button>
        </div>

        <div class="text-center">
          <button
            class="btn btn-link font-PR fs-18 color-text-primary clear-link text-center"
            @click="close"
          >
            {{ $t("btn.cancel") }}
          </button>
        </div>
      </div>
      <div class="px-4 pb-4 pt-2" v-if="sheetState === 2">
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center"
          style="width: 100px; height: 100px"
        >
          <img
            src="@/assets/images/waiting-icon.svg"
            width="25"
            height="auto"
            alt=""
            class="hourglass-rotation"
          />
        </div>
        <div class="my-4">
          <p class="text-center color-text-secondary fs-20 font-PR mb-0 py-3">
            {{ $t("action_sheet.waiter_coming") }}
          </p>
        </div>
        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="close"
          >
            {{ $t("action_sheet.ok") }}
          </button>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import { CLIENT_EVENT } from "@/config/variables";
import TableEventUser from "../../repositories/TableEventUser";
import { mapActions } from "vuex";

export default {
  name: "OrderRejected",
  props: ["value", "rejectedOrderId"],
  components: {
    VueBottomSheet,
  },
  data() {
    return {
      sheetState: 0,
    };
  },
  ...mapActions(["addCartItem"]),
  computed: {
    CLIENT_EVENT() {
      return CLIENT_EVENT;
    },
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    element: function () {
      this.sheetState = 0;
      if (this.element === true) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    open() {
      this.element = true;
      this.$refs.actionSheet.open();
    },
    close() {
      this.element = false;
      this.$refs.actionSheet.close();
    },
    actions: function (action) {
      switch (action) {
        case CLIENT_EVENT.CALL_STAFF:
          this.callStaff();
          break;
        case CLIENT_EVENT.EDIT_ORDER:
          this.goCart();
          break;
        default:
          break;
      }
    },
    async callStaff() {
      const response = await TableEventUser.call();
      if (response.success) {
        this.sheetState = 2;
      }
    },
    async getRejectedOrder(id) {
      return await TableEventUser.getRejectedOrderById(id);
    },
    async goCart() {
      const rejectedOrder = await this.getRejectedOrder(this.rejectedOrderId);

      const that = this;
      rejectedOrder.rejectedItems.forEach(function (item) {
        that.$store.dispatch("addCartItem", item);
      });
      rejectedOrder.acceptedItems.forEach(function (item) {
        that.$store.dispatch("addCartItem", item);
      });

      this.$router.push({ name: "Cart" });

      this.sheetState = 0;
      this.$refs.actionSheet.close();
    },
  },
};
</script>

<style lang="scss">
.bottom-sheet__card {
  height: 100vh !important;

  .bottom-sheet__content {
    height: 100% !important;
  }
}
</style>
