<template>
  <div id="navbar" class="mb-0 mt-auto maxw-600 shadow-top card-border">
    <div class="d-flex flex-row w-100 align-items-center px-1">
      <div class="flex-fill">
        <div
          class="nav w-100 d-flex flex-row align-items-center justify-content-center minh-90 px-4"
        >
          <div
            class="text-center nav-item"
            :class="[currentPage === 'home' ? 'active' : '']"
            @click="changePage('home')"
          >
            <div
              class="py-2 px-3 radius-12 d-flex flex-col aling-items-center justify-content-center"
            >
              <span class="icon home"></span>
              <span class="menu">
                {{ $t("navbar.home") }}
              </span>
            </div>
          </div>
          <div
            class="text-center nav-item"
            :class="[currentPage === 'search' ? 'active' : '']"
            @click="changePage('search')"
          >
            <div
              class="py-2 px-3 radius-12 d-flex flex-col aling-items-center justify-content-center"
            >
              <span class="icon search"></span>
              <span class="menu">
                {{ $t("navbar.search") }}
              </span>
            </div>
          </div>
          <div
            class="text-center nav-item"
            :class="[currentPage === 'category' ? 'active' : '']"
            @click="changePage('category')"
          >
            <div
              class="py-2 px-3 radius-12 d-flex flex-col aling-items-center justify-content-center"
            >
              <span class="icon category"></span>
              <span class="menu">
                {{ $t("navbar.categories") }}
              </span>
            </div>
          </div>
          <div
            v-if="!getIsKiosk"
            class="text-center nav-item"
            :class="[currentPage === 'cart' ? 'active' : '']"
            @click="changePage('cart')"
          >
            <div
              class="py-2 px-3 radius-12 d-flex flex-col aling-items-center justify-content-center"
            >
              <span class="icon cart"></span>
              <span class="menu">
                {{ $t("navbar.cart") }}
              </span>
              <span v-if="cart && cart.length > 0" class="badge">
                {{ cart.length }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink minw-80 maxw-80 text-center">
        <div>
          <QuickAction
            :isKiosk="getIsKiosk"
            :isGuest="getIsGuest"
            :cart="cart"
            @openCart="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import QuickAction from "./../components/QuickAction.vue";
export default {
  name: "NavBar",
  props: ["current"],
  components: {
    QuickAction,
  },
  computed: {
    ...mapState(["cart"]),
    ...mapGetters(["getIsKiosk", "getIsGuest"]),
  },
  data() {
    return {
      currentPage: "",
    };
  },
  methods: {
    changePage: function (index) {
      let route = "/app/" + index;

      if (this.$route.path !== route) {
        this.$router.push(route);
      }

      this.currentPage = index;
    },
  },
  watch: {
    current: function (val) {
      this.currentPage = val;
    },
  },
  mounted() {
    this.currentPage = this.current;
  },
};
</script>
<style lang="scss">
#navbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;
  .nav {
    .nav-item {
      flex: 1 1 auto;
      div {
        position: relative;
        display: inline-block;
        margin: 0 auto 0;
        .icon {
          display: inline-block;
          width: 25px;
          height: 25px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 25px 25px;
          &.home {
            background-image: url("~@/assets/images/home.svg");
          }
          &.search {
            background-image: url("~@/assets/images/search.svg");
          }
          &.category {
            background-image: url("~@/assets/images/category.svg");
          }
          &.cart {
            background-image: url("~@/assets/images/cart.svg");
          }
        }
        .menu {
          display: none;
          color: #ffffff;
        }
        .badge {
          width: auto;
          min-width: 22px;
          height: 22px;
          position: absolute;
          top: -7px;
          right: calc(50% - 30px);
          line-height: 9px;
          border-radius: 11px;
          background-color: var(--primary-color);
          border: 2px solid map_get($colors, "white");
        }
      }
      &.active {
        flex: 0 1 auto !important;
        div {
          background: linear-gradient(
            180deg,
            var(--primary-color) 0%,
            var(--primary-light-color) 100%
          );
          .icon {
            &.home {
              background-image: url("~@/assets/images/home-on.svg") !important;
            }
            &.search {
              background-image: url("~@/assets/images/search-on.svg");
            }
            &.category {
              background-image: url("~@/assets/images/category-on.svg");
            }
            &.cart {
              background-image: url("~@/assets/images/cart-on.svg");
            }
          }
          .menu {
            padding-left: 10px;
            display: inline-block;
            position: relative;
          }
          .badge {
            top: -7px;
            right: -13px;
          }
        }
      }
    }
  }
}
</style>
