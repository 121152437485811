<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-4 bg-custom-gradient"
    v-bind:class="{ 'bg-white': isLoading }"
  >
    <div class="mb-auto mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <img :src="getImgUrl(logo)" class="pb-5 maxw-300 height-auto" />
        <div
          class="spinner-border spinner-border-xxl color-white p-4 mt-5 float-none d-inline-block"
          role="status"
          v-bind:class="{ 'color-text-secondary': isLoading }"
        >
          <span class="sr-only"></span>
        </div>
      </div>
    </div>

    <div class="text-center color-white fs-15 font-PL">
      Made with love by <span class="font-PR">Monty</span>
    </div>
    <div class="load-fonts hidden">
      <span class="font-PM">&nbsp;</span>
      <span class="font-PB">&nbsp;</span>
    </div>
  </div>
</template>
<script>
import TableSessionRepository from "../repositories/TableSessionRepository";
import LocationRepository from "../repositories/LocationRepository";
import { mapGetters } from "vuex";

export default {
  name: "Loading",
  data() {
    return {
      isLoading: true,
      logo: "",
    };
  },
  computed: {
    ...mapGetters(["getLocationId"]),
  },
  created() {
    if (
      this.$route.query.hash != null ||
      this.$route.query.hash !== undefined
    ) {
      this.getTableSession(this.$route.query.hash);
    } else {
      this.$router.push({ name: "Error" });
    }
  },
  mounted() {
    setTimeout(
      async function () {
        await this.getLocation(this.getLocationId);
        this.$router.push({ name: "LanguageSelector" });
      }.bind(this),
      1000
    );
  },
  watch: {
    "$store.state.tableSession": function () {
      this.isLoading = false;
      this.logo = this.$store.state.tableSession.imageUrl;
    },
  },
  methods: {
    async getTableSession(hash) {
      //if (!this.$store.state.tableSession) {
      const data = {
        tableUuId: hash,
      };
      var session = null;
      session = await TableSessionRepository.create(data, (error) => {
        console.log(error);
      });
      await this.$store.dispatch("setSession", session.data);
      console.log(session);
      //}
    },
    async getLocation(locationId) {
      const location = await LocationRepository.getById(locationId);
      await this.$store.dispatch("setLocation", location.data);
    },
  },
};
</script>
