<template>
  <div
    class="d-flex flex-column align-items-center bg-color-dark-gray w-100 rounded-4"
  >
    <button
      class="btn w-100 checkbox-element bg-color-gray radius-12 d-flex flex-row align-items-center"
      @click="changeStateValue(!element.state)"
      :class="element.state ? 'active' : ''"
    >
      <div class="flex-fill py-0">
        <p class="font-PR text-start ps-1 fs-18 my-0 lh-20">
          {{ element.productOptionInfo.name }}
        </p>
        <p class="font-PR text-start ps-1 fs-15 my-0 lh-20">
          <small>
            {{ element.productOptionInfo.description }}
          </small>
        </p>
      </div>
      <div
        class="flex-shrink px-2 minw-100"
        v-if="element.isSubstractable || !element.isBaseOption"
      >
        <p class="font-PR text-start ps-1 fs-15 my-0">{{ optionPrice }}</p>
      </div>
      <div class="checkbox flex-shrink py-2">
        <div><span></span></div>
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: "ProductOptionItem",
  props: ["value"],
  computed: {
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    optionPrice() {
      var price = 0;
      var prefix = "";
      if (this.element.isBaseOption) {
        price = this.formatPrice(this.element.substactPrice);
        prefix = "-";
      } else {
        price = this.formatPrice(this.element.price);
        prefix = "+";
      }
      if (price == null) {
        return "";
      }
      return `${prefix}${price}`;
    },
  },
  methods: {
    changeStateValue(newState) {
      this.element.state = newState;
      this.$emit("input", this.element);
    },
  },
};
</script>
<style lang="scss"></style>
