<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
    :class="[items.length > 0 ? 'pb-170px' : '']"
  >
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
      :class="[items.length > 0 ? 'h-auto' : 'h-100']"
    >
      <HeaderNav
        :backButtonBg="'bg-color-gray'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="$t(`checkout.title`)"
      />
      <transition name="fade" mode="out-in" appear>
        <div
          class="mx-auto mt-0 d-flex flex-column w-100 maxw-600 bg-color-white position-relative"
        >
          <div v-if="items.length > 0">
            <div v-for="(item, index) in items" :key="index">
              <ProductItem
                :showCartLine="false"
                :showSelectedOptionsBlock="true"
                :showRightInfo="true"
                :product="item"
              />
            </div>
          </div>
          <div
            v-else
            class="mx-auto px-3 mt-5 w-100 maxw-600 bg-color-white position-relative"
          >
            <div class="my-auto">
              <div class="h-100 text-center align-items-center">
                <img
                  src="@/assets/images/cart.svg"
                  width="30%"
                  height="auto"
                  alt=""
                />
              </div>
              <div>
                <p
                  class="font-PR text-center color-text-secondary fs-20 pt-5 mb-0"
                >
                  {{ $t(`checkout.currently_empty`) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <CheckoutLine @send-checkout="choosePayment" :sum-price="price" />
      <NavBar current="" />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import ProductItem from "../components/ProductItem.vue";
import TableSessionRepository from "../repositories/TableSessionRepository";
import CheckoutLine from "../components/CheckoutLine.vue";
import { mapGetters } from "vuex";

export default {
  name: "Checkout",
  components: {
    CheckoutLine,
    HeaderNav,
    ProductItem,
    NavBar,
  },
  data() {
    return {
      items: [],
      price: 0,
    };
  },
  created() {
    this.getSummary();
  },
  computed: {
    ...mapGetters(["getTableSessionObject", "getLang"]),
  },
  methods: {
    async getSummary() {
      console.log(this.getTableSessionObject);
      const data = {
        tableIdToSummarize: this.getTableSessionObject.tableId,
        receiptLanguageCode: this.getLang,
      };
      const response = await TableSessionRepository.summary(data);
      this.items = response.items;
      this.price = response.sumPrice;
    },
    choosePayment: function () {
      this.$router.push({ name: "CheckoutPayment" });
    },
  },
};
</script>
