import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    orders: JSON.parse(localStorage.getItem("orders")),
    order: JSON.parse(localStorage.getItem("order")),
    cart: JSON.parse(localStorage.getItem("cart")),
    tableSession: JSON.parse(localStorage.getItem("tableSession")),
    location: JSON.parse(localStorage.getItem("location")),
    lang: localStorage.getItem("lang"),
    currency: "Ft",
    adult: JSON.parse(localStorage.getItem("adult")) ?? null,
  },
  mutations: {
    UPDATE_CART(state, payload) {
      state.cart = payload;
      localStorage.setItem("cart", JSON.stringify(payload));
    },
    RESET_CART(state) {
      state.cart = null;
      state.order = null;
      localStorage.setItem("cart", null);
      localStorage.setItem("order", null);
    },
    SET_LOCATION(state, payload) {
      state.location = payload;
      localStorage.setItem("location", JSON.stringify(payload));
    },
    SET_SESSION(state, payload) {
      state.tableSession = payload;
      localStorage.setItem("tableSession", JSON.stringify(payload));
    },
    SET_LANG(state, payload) {
      state.lang = payload;
      localStorage.setItem("lang", payload);
    },
    SET_ORDER_TYPE(state, payload) {
      state.order = payload;
      localStorage.setItem("order", JSON.stringify(payload));
    },
    SET_ADULT(state, payload) {
      state.adult = payload;
      localStorage.setItem("adult", JSON.stringify(payload));
    },
  },
  actions: {
    addCartItem(context, payload) {
      const cart = context.state.cart ?? [];
      cart.push(payload);
      context.commit("UPDATE_CART", cart);
    },
    removeCartItem(context, payload) {
      const cart = context.state.cart;
      cart.splice(payload, 1);
      context.commit("UPDATE_CART", cart);
    },
    changeCartItem(context, payload) {
      const index = payload.index;
      const item = payload.item;
      const cart = context.state.cart;
      cart.splice(index, 1, item);
      context.commit("UPDATE_CART", cart);
    },
    setLocation(context, payload) {
      context.commit("SET_LOCATION", payload);
    },
    setLang(context, payload) {
      context.commit("SET_LANG", payload);
    },
    setSession(context, payload) {
      context.commit("SET_SESSION", payload);
    },
    setOrderType(context, payload) {
      let order = context.state.order || {};
      order.type = payload;
      context.commit("SET_ORDER_TYPE", order);
    },
    resetCart(context) {
      context.commit("RESET_CART");
    },
    setAdult(context, payload) {
      context.commit("SET_ADULT", payload);
    },
  },
  getters: {
    getLang: (state) => {
      return state.lang ?? state.location?.defaultLanguage ?? "en";
    },
    getCustomStyle: (state) => {
      return state.tableSession.userDesignString;
    },
    getTableSessionObject: (state) => {
      return state.tableSession;
    },
    getIsKiosk: (state) => {
      console.log(state.tableSession);
      return (
        state.tableSession.isTable == false &&
        state.tableSession.owner == "partner"
      );
    },
    getIsGuest: (state) => {
      return (
        state.tableSession.isTable == false &&
        state.tableSession.owner == "user"
      );
    },
    getLocationObject: (state) => {
      return state.location;
    },
    getLocationName: (state) => {
      return state.location.name;
    },
    getLocationId: (state) => {
      return state.tableSession.locationId;
    },
    getDefaultLanguage: (state) => {
      return state.location.defaultLanguage;
    },
    getSelectableLanguages: (state) => {
      const data = state.location.languages;
      const firstPlaceLanguages = ["en", "hu"];

      let filtered = data;
      for (const language of firstPlaceLanguages) {
        const languageElement = filtered.find((obj) => obj.value === language);
        if (languageElement) {
          filtered = filtered.filter((obj) => obj.value !== language);
          filtered.unshift(languageElement);
        }
      }
      return filtered;
    },
    getCart: (state) => {
      return state.cart;
    },
    getOrder: (state) => {
      return state.order;
    },
    getOrders: (state) => {
      return state.orders;
    },
    getCurrency: (state) => {
      return state.currency;
    },
    getCartSumPrice: (state) => {
      let sumPrice = 0;
      state?.cart?.forEach(function (item) {
        sumPrice += item.qty * item.price;
      });
      return sumPrice;
    },
    getAdult: (state) => {
      return state.adult;
    },
  },
});
