<template>
  <div
    id="page-clear"
    class="d-flex bg-color-white flex-column align-items-center justify-content-center w-100 w-100vw overflow-hidden"
    :style="mobileFullScreen()"
  >
    <div
      class="mx-auto my-auto w-100 maxw-600 bg-color-white position-relative"
    >
      <transition name="fade" mode="out-in" appear>
        <swiper
          v-if="steps.length > 0"
          ref="mySwiper"
          class="swiper position-relative d-block w-100"
          :options="swiperOption"
          @slideChange="slideChange()"
        >
          <swiper-slide v-for="step in steps" :key="step.id" class="w-100">
            <div class="w-100 maxw-600 px-4">
              <div class="w-100 icon">
                <img
                  width="auto"
                  height="200px"
                  :src="getImgUrl(step.imageUrl)"
                  :alt="step.info.title"
                />
              </div>
              <div class="w-100 content pt-3">
                <h1 class="text-left color-text-primary font-PM">
                  {{ step.info.title }}
                </h1>
                <p class="text-left color-text-primary font-PR mb-0">
                  {{ step.info.description }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </transition>
      <div class="w-100 px-4">
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div
      class="mx-auto mb-3 mt-0 w-100 maxw-600 bg-color-white position-relative"
    >
      <div class="py-4 px-4 row">
        <div class="col-6">
          <button
            id="swiper-next"
            class="swiper-button-next btn btn-monty px-5 py-2 color-white font-PR fs-18"
            slot="button-next"
          >
            {{ $t("btn.next") }}
          </button>
        </div>
        <div class="col-6 text-end">
          <button
            id="skip"
            @click="skipAction"
            class="btn btn-link font-PR fs-18 color-text-primary clear-link"
          >
            {{ $t("btn.skip") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialRepository from "../repositories/TutorialRepository";

import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import { mapGetters } from "vuex";
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
const { Swiper, SwiperSlide, directive } = getAwesomeSwiper(SwiperClass);
export default {
  name: "Tutorial",
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters(["getLang"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this.getSteps();
  },
  data() {
    return {
      steps: [],
      errorStr: "",
      location: null,
      gettingLocation: false,
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: "#swiper-next",
          prevEl: "#swiper-prev",
        },
      },
    };
  },
  methods: {
    async getSteps() {
      const tutorial = await TutorialRepository.getByCode(this.getLang);
      this.steps = tutorial.data;
    },
    // async getLocation() {
    //   return new Promise((resolve, reject) => {
    //     if (!("geolocation" in navigator)) {
    //       reject(new Error("Geolocation is not available."));
    //     }
    //
    //     navigator.geolocation.getCurrentPosition(
    //       (pos) => {
    //         resolve(pos);
    //       },
    //       (err) => {
    //         reject(err);
    //       }
    //     );
    //   });
    // },
    // async locateMe() {
    //   this.gettingLocation = true;
    //   try {
    //     this.gettingLocation = false;
    //     this.location = await this.getLocation();
    //     // TODO - Implement location verification logic
    //     console.log(this.location.coords.latitude);
    //     console.log(this.location.coords.longitude);
    //     this.$router.push({ name: "Home" });
    //   } catch (e) {
    //     this.gettingLocation = false;
    //     this.$router.push({ name: "Home" });
    //   }
    // },
    slideChange: function () {
      this.swiper.activeIndex + 1;
      const index = this.swiper.activeIndex;
      if (this.steps.length < index) {
        this.goHome();
      }
    },
    skipAction: function () {
      this.goHome();
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
