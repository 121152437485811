const ORDER_TYPE = {
  EAT_IN: "eatIn",
  TAKE_AWAY: "takeAway",
};

const PAYMENT_METHOD = {
  CASH: "cash",
  POS_TERMINAL: "posTerminal",
  ONLINE_BARION: "online",
};

const CLIENT_EVENT = {
  CALL_STAFF: "callStaff",
  PAYMENT_REQUEST: "paymentRequest",
  EDIT_ORDER: "editOrder",
};

const ORDER_EVENT_STATUS = {
  BACKLOG: "Backlog",
  REJECTED: "Rejected",
  ACCEPTED: "Accepted",
  READY: "Ready",
};

const SOCKET_MESSAGE_TYPES = {
  ORDER_REJECTED: "order_rejected",
};

export {
  ORDER_TYPE,
  PAYMENT_METHOD,
  CLIENT_EVENT,
  ORDER_EVENT_STATUS,
  SOCKET_MESSAGE_TYPES,
};
