import apiService from "../services/apiService";
import store from "../store";
import { EventBus } from "../plugins/event-bus";

export default {
  getRecommendationsByLocationId(locationId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/recomendations/${locationId}`)
        .then((response) => resolve(this.buildProductsData(response.data)))
        .catch((error) => reject(error));
    });
  },
  getByCategoryId(categoryId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/categories/${categoryId}`)
        .then((response) => resolve(this.buildProductsData(response.data)))
        .catch((error) => reject(error));
    });
  },
  getById(productId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/${productId}`)
        .then((response) => resolve(this.buildProductData(response.data)))
        .catch((error) => reject(error));
    });
  },
  getAllergensById(productId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/allergens/${productId}`)
        .then((response) => resolve(this.buildAllergensData(response.data)))
        .catch((error) => reject(error));
    });
  },
  getOptionsById(productId) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/options/${productId}`)
        .then((response) => resolve(this.buildOptionsData(response.data)))
        .catch((error) => reject(error));
    });
  },
  getOptionInfo(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`getOptionInfo`, data)
        .then((response) => resolve(this.buildOptionsInfoData(response.data)))
        .catch((error) => reject(error));
    });
  },
  buildProductsData(items) {
    const elements = [];
    let hasAgeGate = false;

    if (items?.data && items.data.length > 0) {
      items.data.forEach(function (item) {
        const productInfo = item?.productInfos.find(
          ({ language }) => language === store.state.lang
        );
        const product = {
          id: item.id,
          price: item.price,
          unit: productInfo?.unit,
          imgURL: item.productImages[0]?.imageUrl,
          gallery: item.productImages,
          hasAgeGate: item.hasAgeGate,
          name: productInfo?.name,
          desc: productInfo?.description,
          variation: productInfo?.variation,
        };
        if (hasAgeGate === false && item.hasAgeGate) {
          hasAgeGate = true;
        }
        elements.push(product);
      });
    }

    if (hasAgeGate) {
      EventBus.$emit("openAgeGate", true);
    }

    return elements;
  },
  buildProductData(items) {
    let product = {};
    let hasAgeGate = false;

    if (items?.data) {
      const productInfo = items.data?.productInfos.find(
        ({ language }) => language === store.state.lang
      );
      product = {
        id: items.data.id,
        price: items.data.price,
        unit: productInfo?.unit,
        imgURL: items.data.productImages[0]?.imageUrl,
        gallery: items.data.productImages,
        hasAgeGate: items.data.hasAgeGate,
        name: productInfo?.name,
        desc: productInfo?.description,
        variation: productInfo?.variation,
      };
      if (hasAgeGate === false && items.data.hasAgeGate) {
        hasAgeGate = true;
      }
    }

    if (hasAgeGate) {
      EventBus.$emit("openAgeGate", true);
    }

    return product;
  },
  buildAllergensData(items) {
    const elements = [];
    if (items?.data && items.data.length > 0) {
      items.data.forEach(function (item) {
        const allergenInfos = item?.allergenInfos.find(
          ({ language }) => language === store.state.lang
        );
        const product = {
          id: item.id,
          name: allergenInfos?.name,
        };
        elements.push(product);
      });
    }
    return elements;
  },
  buildOptionsData(items) {
    const response = {
      baseOptions: [],
      extraOptionsCategories: [],
    };
    if (items?.data) {
      response.baseOptions = this.processBaseOptions(items.data.baseOptions);
      response.extraOptionsCategories = this.processExtraOptionsCategories(
        items.data.extraOptionCategories
      );
    }
    console.log(items);
    console.log(response);
    return response;
  },
  buildOptionsInfoData(data) {
    const response = [];
    data.data.productInfos.forEach((item) => {
      const options = {
        exeptBaseOptionInfo: [],
        extraOptionInfo: [],
        extraProductInfo: [],
      };

      item.exeptBaseOptionInfos.forEach((item) => {
        options.exeptBaseOptionInfo.push({
          ...item.optionInfos[0],
          price: item.price,
        });
      });

      item.extraOptionInfos.forEach((item) => {
        options.extraOptionInfo.push({
          ...item.optionInfos[0],
          price: item.price,
        });
      });

      item.extraProductInfos.forEach((item) => {
        options.extraOptionInfo.push({
          ...item.optionInfos[0],
          price: item.price,
        });
      });

      response.push({
        productId: item.productId,
        options: options,
      });
    });
    return response;
  },
  processBaseOptions(baseOptions) {
    const response = [];
    baseOptions.forEach(function (item) {
      const baseOptionInfo = item?.baseOptionInfos.find(
        ({ language }) => language === store.state.languageCode
      );
      const productOptionInfo = item?.productOption.optionInfos.find(
        ({ language }) => language === store.state.lang
      );
      const baseOption = {
        id: item.id,
        state: item.defaultSelected,
        defaultSelected: item.defaultSelected,
        isSubstractable: item.isSubstractable,
        locationId: item.locationId,
        productId: item.productId,
        productOptionId: item.productOptionId,
        price: item.productOption.price,
        substactPrice: item.substactPrice,
        baseOptionInfo: baseOptionInfo,
        productOptionInfo: productOptionInfo,
        isBaseOption: true,
      };
      response.push(baseOption);
    });
    return response;
  },
  processExtraOptionsCategories(extraOptionCategories) {
    const response = [];
    extraOptionCategories.forEach(function (item) {
      const options = [];
      const extraOptionCategoryInfo = item?.extraOptionCategoryInfos.find(
        ({ languageCode }) => languageCode === store.state.lang
      );
      item.productOptions.forEach(function (option) {
        const productOptionInfo = option?.optionInfos.find(
          ({ language }) => language === store.state.lang
        );
        const extraOption = {
          id: option.id,
          locationId: option.locationId,
          price: option.price,
          state: false,
          isSubstractable: false,
          productOptionInfo: productOptionInfo,
          isBaseOption: false,
        };
        options.push(extraOption);
      });
      const extraOptionCategory = {
        id: item.id,
        selectionLimit: item.selectionLimit,
        locationId: item.locationId,
        priceIncluded: item.priceIncluded,
        extraOptionCategoryInfo: extraOptionCategoryInfo,
        options: options,
      };
      response.push(extraOptionCategory);
    });
    return response;
  },
};
