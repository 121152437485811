<template>
  <div class="mx-auto maxw-600 w-100 position-relative">
    <div
      class="d-flex align-items-center justify-content-between bd-highlight mb-0"
    >
      <div class="p-0 bd-highlight minw-50">
        <button
          @click="backAction"
          class="btn btn-block backBtn bg-color-white"
          :class="backButtonBg ?? ''"
        >
          <span></span>
        </button>
      </div>
      <div class="p-2 bd-highlight">
        <h1 class="font-PM fs-25 my-0 uppercased" :class="classes">
          {{ title }}
        </h1>
      </div>
      <div class="p-2 bd-highlight minw-50"></div>
    </div>
    <div
      class="sticky-navbar bg-color-white py-1 shadow-bottom card-border"
      :class="showStickyHeader ? 'active' : ''"
    >
      <div class="d-flex w-100 flex-row px-2 pb-1 align-items-center">
        <div class="ml-0 minw-60">
          <button
            @click="backAction"
            class="btn btn-block backBtn sticky bg-color-white"
            :class="backButtonBg ?? ''"
          >
            <span></span>
          </button>
        </div>
        <div class="mx-auto">
          <h5 class="font-PM fs-20 uppercased my-0">{{ title }}</h5>
        </div>
        <div class="mr-0 minw-60"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "header-nav",
  props: ["backAction", "title", "backButtonBg", "classes"],
  data() {
    return {
      windowTop: window.scrollY,
    };
  },
  computed: {
    showStickyHeader() {
      return this.windowTop > 60;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll(e) {
      this.windowTop = e.target.scrollTop;
    },
  },
};
</script>
<style lang="scss">
.btn {
  &.backBtn {
    padding: 2px !important;
    width: 45px;
    height: 45px;
    background-color: map_get($colors, "gray");
    border-radius: 30%;
    span {
      display: block;
      width: 41px;
      height: 38px;
      border-radius: 50%;
      background: url("~@/assets/images/back-icon.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 25px 21px;
    }
    &.sticky {
      width: 35px;
      height: 35px;
      background-color: map_get($colors, "gray");
      border-radius: 30%;
      span {
        width: 31px;
        height: 28px;
        background-size: 20px 16px;
      }
    }
  }
}
.sticky-navbar {
  position: fixed;
  transition: top 0.5s;
  top: -100px;
  width: 100%;
  max-width: 600px;
  margin-left: -1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  &.active {
    z-index: 50;
    top: 0px;
  }
}
</style>
