<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-0"
  >
    <div class="my-auto">
      <div class="mt-auto">
        <div
          class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center px-2"
        >
          <div
            class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
          >
            <img src="@/assets/images/cart-on.svg" width="80" />
          </div>
          <div
            class="text-center color-text-secondary mt-3"
            v-html="$t(`checkout.wait_for_waiter`)"
          ></div>
        </div>
      </div>
      <div class="w-100 px-4 mt-4">
        <div class="bg-color-gray radius-12 p-3 mx-auto">
          <div
            class="d-flex flex-column w-100 align-items-center justify-content-center text-center color-text-secondary"
          >
            <div class="d-flex justify-content-between w-100">
              <div>{{ $t(`order.order_number`) }}</div>
              <div class="font-PB">{{ this.order_id }}</div>
            </div>

            <div class="d-flex justify-content-between w-100">
              <div>{{ $t(`product.total_price`) }}</div>
              <div class="font-PB">{{ formatPrice(sumPrice) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 row mt-2">
        <div class="col-12">
          <button
            class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
            slot="button-next"
            @click="goHome"
          >
            {{ $t("btn.go_home") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableSessionRepository from "../repositories/TableSessionRepository";
import { mapGetters } from "vuex";
export default {
  name: "CheckoutCompleted",
  data() {
    return {
      order_id: null,
      sumPrice: 0,
    };
  },
  computed: {
    ...mapGetters(["getTableSessionObject", "getLang"]),
  },
  mounted() {
    this.getSummary();
    console.log(this.getTableSessionObject);
    if (
      this.$route.params.order_id != null ||
      this.$route.params.order_id !== undefined
    ) {
      this.order_id = this.$route.params.order_id;
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
    async getSummary() {
      const data = {
        tableIdToSummarize: this.getTableSessionObject.tableId,
        receiptLanguageCode: this.getLang,
      };
      const response = await TableSessionRepository.summary(data);
      console.log(response);
      this.sumPrice = response.sumPrice;
    },
  },
};
</script>
