<template>
  <div>
    <vue-bottom-sheet
      ref="actionSheet"
      :background-scrollable="false"
      :background-clickable="false"
      max-width="600px"
      max-height="350px"
      @closed="element = false"
    >
      <div class="px-4 pb-4 pt-2">
        <h1 class="font-PR text-center fs-24 pb-3">
          {{ $t(`action_sheet.are_you_18_plus`) }}
        </h1>
        <p
          class="font-PR text-center color-text-secondary fs-18 pb-3"
          v-html="$t('action_sheet.content_18_plus_only')"
        ></p>
        <div class="my-3">
          <button
            class="btn btn-block btn-monty w-100 py-3 color-white font-PR fs-18"
            @click="yes(redirectRoute)"
          >
            {{ $t(`btn.yes`) }}
          </button>
        </div>

        <div class="text-center">
          <button
            class="btn btn-link font-PR fs-18 color-text-primary clear-link text-center"
            @click="no"
          >
            {{ $t(`btn.no`) }}
          </button>
        </div>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
export default {
  name: "action-sheet",
  props: ["value", "redirectRoute"],
  components: {
    VueBottomSheet,
  },
  computed: {
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    element: function () {
      if (this.element === true) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    open() {
      this.element = true;
      this.$refs.actionSheet.open();
    },
    close() {
      this.element = false;
      this.$refs.actionSheet.close();
    },
    async yes(route) {
      await this.$store.dispatch("setAdult", true);
      this.element = true;
      if (this.route !== null) {
        this.$router.push(route);
      }
      this.$refs.actionSheet.close();
    },
    async no() {
      await this.$store.dispatch("setAdult", false);
      this.element = false;
      this.$refs.actionSheet.close();
    },
  },
};
</script>
<style lang="scss">
.bottom-sheet__card {
  height: 100vh !important;
  .bottom-sheet__content {
    height: 100% !important;
  }
}
</style>
