<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start"
  >
    <div
      class="mx-auto px-3 pt-2 w-100 maxw-600 bg-color-white position-relative"
    >
      <!-- Welcome -->
      <div class="w-100 d-flex flex-row align-items-center pt-4 pb-2">
        <div class="flex-fill" style="width: 85%; word-break: break-word">
          <h1 class="fs-28">
            {{ $t("home.welcome", { name: locationName }) }}
          </h1>
        </div>
      </div>
      <!-- Separator -->
      <!-- <hr class="mb-0" /> -->
      <transition name="fade" mode="out-in" appear>
        <div>
          <!-- Search  -->
          <SearchBox
            :label="$t('btn.search')"
            v-on:searchAction="search"
            v-model="keywords"
          />

          <!-- Category -->
          <div class="mb-3 mt-2">
            <button
              @click="goToCategories"
              class="btn btn-block btn-monty w-100 py-2 color-white font-PR fs-18"
            >
              {{ $t("home.go_to_menu") }}
            </button>
          </div>
          <!-- Separator -->
          <!-- <hr class="mb-3" /> -->
          <!-- Popular Boxes -->
          <PopularBox :items="recommendations" />
          <hr class="mb-3 mt-0" />
          <PopularBox :items="recommendations" />
          <hr class="mb-3 mt-0" />
          <PopularBox :items="recommendations" />
        </div>
      </transition>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar :current="'home'" />
    </div>
  </div>
</template>

<script>
import ProductRepository from "../repositories/ProductRepository";

import { mapGetters } from "vuex";
import SearchBox from "./../components/SearchBox.vue";
import PopularBox from "./../components/PopularBox.vue";
import NavBar from "./../components/NavBar.vue";

export default {
  name: "Home",
  components: {
    SearchBox,
    PopularBox,
    NavBar,
  },
  data() {
    return {
      keywords: "",
      recommendations: [],
      locationName: "",
    };
  },
  computed: {
    ...mapGetters(["getLocationName", "getLocationId"]),
  },
  created() {
    this.getRecommendations(this.getLocationId);
    this.locationName = this.getLocationName;
  },
  methods: {
    async getRecommendations(locationId) {
      this.recommendations =
        await ProductRepository.getRecommendationsByLocationId(locationId);
    },
    goToCategories() {
      this.$router.push({ name: "Category" });
    },
    search: function (keywords) {
      this.$router.push({
        name: "Search",
        query: { name: keywords },
      });
    },
  },
};
</script>
