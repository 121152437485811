<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-4"
  >
    <div class="mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          <img src="@/assets/images/done-icon.svg" width="80" />
        </div>
        <div
          class="text-center color-text-secondary mt-3"
          v-html="$t(`checkout.completed`)"
        ></div>
      </div>
    </div>
    <div class="w-100 px-4 mt-4">
      <div class="bg-color-gray radius-12 p-3 mx-auto">
        <div
          class="d-flex flex-column w-100 align-items-center justify-content-center text-center color-text-secondary"
        >
          <!--          <div class="d-flex justify-content-between w-100">-->
          <!--            <div>Subtotal</div>-->
          <!--            <div>11 350 Ft</div>-->
          <!--          </div>-->
          <!--          <div class="d-flex justify-content-between w-100">-->
          <!--            <div>Service Fee & Delivery</div>-->
          <!--            <div>100 Ft</div>-->
          <!--          </div>-->
          <div class="d-flex justify-content-between w-100">
            <div>{{ $t(`product.total_price`) }}</div>
            <div>{{ formatPrice(0) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 row mt-5">
      <div class="col-12">
        <button
          class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
          slot="button-next"
          @click="goHome"
        >
          {{ $t("btn.go_home") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckoutCompleted",
  mounted() {},
  data() {
    return {};
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
