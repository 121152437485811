<template>
  <div id="app" class="d-flex align-items-center justify-content-center">
    <router-view></router-view>
    <AgeGateSheet
      v-model="ageGateSheetIsOpen"
      v-bind:redirectRoute="ageGateRoute"
    />
    <OrderRejected
      v-model="orderRejectedSheetIsOpen"
      :rejected-order-id="rejectedOrderId"
    />
  </div>
</template>

<script>
import socket from "./services/webSocketService";
import AgeGateSheet from "./components/bottomsheets/AgeGateSheet.vue";
import OrderRejected from "./components/bottomsheets/OrderRejected.vue";
import { EventBus } from "@/plugins/event-bus";
import { mapGetters } from "vuex";
import { SOCKET_MESSAGE_TYPES } from "@/config/variables";
export default {
  name: "Root",
  data() {
    return {
      connection: null,
      ageGateSheetIsOpen: false,
      orderRejectedSheetIsOpen: false,
      rejectedOrderId: null,
      ageGateRoute: null,
    };
  },
  components: {
    AgeGateSheet,
    OrderRejected,
  },
  computed: {
    ...mapGetters(["getAdult"]),
  },
  created() {
    if (this.$store.state?.tableSession?.userDesignString !== undefined) {
      this.overrideStyle(this.$store.state?.tableSession?.userDesignString);
    }
    this.connection = socket;

    EventBus.$on("openAgeGate", this.openAgeGate);
    EventBus.$on("socketMessage", this.handleSocketMessages);
  },
  methods: {
    openAgeGate(route) {
      if (this.getAdult === null || this.getAdult == false) {
        this.ageGateRoute = route;
        this.ageGateSheetIsOpen = true;
        //EventBus.$off("openAgeGate");
      }
    },
    handleSocketMessages(m) {
      if (m.Message === SOCKET_MESSAGE_TYPES.ORDER_REJECTED) {
        this.openOrderRejected();
        this.rejectedOrderId = m.DataId;
      }
    },
    openOrderRejected() {
      this.orderRejectedSheetIsOpen = true;
    },
    overrideStyle(string) {
      const css = `:root{${string}}`,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

      head.appendChild(style);
      style.type = "text/css";

      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
  },
  watch: {
    "$store.state.tableSession.userDesignString": function () {
      this.overrideStyle(this.$store.state.tableSession.userDesignString);
    },
  },
};
</script>
