import store from "../store";
import { EventBus } from "@/plugins/event-bus";

const socket = new WebSocket(process.env.VUE_APP_WSS_URL);

socket.onerror = function (event) {
  console.log("Error: ");
  console.log(event);
};

socket.onmessage = function (event) {
  console.log("Message: ");
  console.log(event);
  handleEvent(event);
};

socket.onopen = function (event) {
  connectToWebSocket(event);
};

function connectToWebSocket(event) {
  if (store?.state?.tableSession?.uuid) {
    socket.send(`${store.state.tableSession.uuid}@register`);
    console.log(`${store.state.tableSession.uuid}@register`);
    console.log("Successfully connected to the echo websocket server...");
    console.log(event);
  } else {
    setTimeout(function () {
      console.log("Retrying connection...");
      connectToWebSocket();
    }, 1000);
  }
}

function handleEvent(e) {
  if (e?.data && isJsonString(e.data)) {
    EventBus.$emit("socketMessage", JSON.parse(e.data));
  }
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export default socket;
