import en from "@/locals/en.json";
import hu from "@/locals/hu.json";
import he from "@/locals/he.json";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || process.env.VUE_APP_DEFAULT_LANG,
  messages: {
    hu: hu,
    en: en,
    he: he,
  },
});
