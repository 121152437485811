import apiService from "../services/apiService";
import store from "../store";

export default {
  create(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`tableSession/create`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  summary(data) {
    return new Promise((resolve, reject) => {
      apiService
        .post(`tableSession/summary`, data)
        .then((response) => resolve(this.buildOrderData(response.data)))
        .catch((error) => reject(error));
    });
  },
  buildOrderData(response) {
    const summary = [];
    if (response?.data?.allOrders.length > 0) {
      response.data.allOrders.forEach(function (session) {
        session?.eventItems?.forEach(function (items) {
          const productInfo = items?.product?.productInfos?.find(
            ({ language }) => language === store.state.lang
          );
          const product = {
            id: items.productId,
            price: items.productSumPrice,
            qty: items.productQuantity,
            imgURL: items.product?.productImages?.[0]?.imageUrl,
            gallery: items.product?.productImages,
            name: productInfo?.name,
            variation: productInfo?.variation,
          };
          summary.push(product);
        });
      });
    }
    return {
      items: [...summary],
      sumPrice: response.data.tableSumPrice,
      summaryUuid: response.data.summaryUuid,
    };
  },
};
