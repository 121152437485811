<template>
  <div
    id="page"
    class="d-flex bg-color-white flex-column align-items-start justify-content-start product_page"
  >
    <ProductOptions
      v-if="openOptions"
      :id="product.id"
      :quantity="local_qty"
      @close-product-options="closeProductOptions"
    />
    <div
      v-if="isVisible(product.hasAgeGate)"
      class="mx-auto px-3 pt-2 pb-2 w-100 maxw-600 bg-color-gray image-wrapper position-relative card-border"
    >
      <HeaderNav
        :backButtonBg="'bg-color-white'"
        :backAction="
          () => {
            this.$router.go(-1);
          }
        "
        :title="product.name"
        :classes="'uppercased'"
      />

      <transition
        name="fade"
        mode="out-in"
        appear
        v-if="isVisible(product.hasAgeGate)"
      >
        <div class="pb-4 bg-color-gray image-wrapper">
          <div class="thumbnail text-center px-3 pb-2">
            <ProductGallery :gallery="gallery_items" />
          </div>
        </div>
      </transition>
    </div>
    <transition
      name="fade"
      mode="out-in"
      appear
      v-if="isVisible(product.hasAgeGate)"
    >
      <div class="w-100 h-100 d-flex flex-column mt-n3 mx-auto maxw-600 zi-99">
        <div class="text-center">
          <div
            class="flex-shrink d-inline-block mx-auto radius-30 text-center qty px-1 py-1 pb-1 bg-color-gray card-border"
          >
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-decrese shadow"
                  @click="decrease"
                >
                  -
                </button>
              </div>
              <div class="px-4 minw-80">{{ local_qty }} {{ product.unit }}</div>
              <div>
                <button
                  class="btn btn-block btn-qty btn-qty-increase shadow"
                  @click="increase"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 mb-auto mx-auto maxw-600 px-4 py-4 text-center">
          <div
            class="price d-inline-block px-4 py-1 mx-auto font-PR fs-20 text-color-primary radius-10"
          >
            {{ formatPrice(product.price) }} / {{ product.unit }}
          </div>
          <div>
            <h1
              class="font-PM fs-28 mb-0 color-text-primary text-start pt-4 uppercased"
            >
              {{ product.name }}
            </h1>
            <h1 class="font-PM fs-18 color-text-secondary text-start pt-2">
              {{ product.variation }}
            </h1>
            <h3 class="font-PR fs-18 color-text-primary text-start pt-3">
              {{ $t(`product.allergens`) }}:
            </h3>
            <p class="font-PR fs-16 color-text-secondary text-start">
              {{ allergens }}
            </p>
            <h3 class="font-PR fs-18 color-text-primary text-start">
              {{ $t(`product.description`) }}:
            </h3>
            <p class="font-PR fs-16 color-text-secondary text-start">
              {{ product.desc }}
            </p>
          </div>
        </div>
        <div class="w-100 mt-auto mx-auto maxw-600 px-4 pb-3 zi-99">
          <div
            class="w-100 d-flex flex-row align-items-center justify-content-between"
          >
            <div>
              <h3 class="font-PM fs-18 mb-0 color-text-primary text-start">
                {{ $t("product.total_price") }}:
              </h3>
              <h3 class="font-PM fs-20 mb-0 text-color-primary text-start">
                {{ formatPrice(total_price) }}
              </h3>
            </div>
            <div>
              <button
                @click="openProductOptions"
                class="btn btn-block btn-monty w-100 py-2 px-4 color-white font-PR fs-18"
              >
                {{ $t("product.options") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-else class="mb-auto mt-auto">
      <div
        class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center"
      >
        <div
          class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
        >
          !
        </div>
        <div class="fs-48">
          {{ $t(`product.sorry`) }}
        </div>
        <div
          class="text-center color-text-secondary mt-3"
          v-html="$t('action_sheet.content_18_plus_only')"
        ></div>
      </div>
    </div>
    <div class="w-100 mt-auto mx-auto maxw-600 zi-99">
      <!-- NavBar -->
      <NavBar />
    </div>
  </div>
</template>

<script>
import ProductRepository from "../repositories/ProductRepository";
import NavBar from "../components/NavBar.vue";
import HeaderNav from "../components/HeaderNav.vue";
import ProductOptions from "../components/ProductOptions.vue";
import ProductGallery from "../components/ProductGallery.vue";
import { mapGetters } from "vuex";
export default {
  name: "Product",
  components: {
    ProductGallery,
    ProductOptions,
    HeaderNav,
    NavBar,
  },
  created() {
    if (this.$route.params.id != null || this.$route.params.id !== undefined) {
      this.getProduct(this.$route.params.id);
      this.getAllergens(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters(["getAdult"]),
    total_price: function () {
      return parseInt(this.product.price) * parseInt(this.local_qty);
    },
    gallery_items: function () {
      return {
        name: this.product.name,
        images: this.product.gallery,
      };
    },
  },
  data() {
    return {
      product: [],
      allergens: [],
      sheetIsOpen: false,
      local_qty: 1,
      openOptions: false,
      isAdult: null,
    };
  },
  methods: {
    async getProduct(id) {
      this.product = await ProductRepository.getById(id);
    },
    async getAllergens(id) {
      const allergenItems = await ProductRepository.getAllergensById(id);
      const allergensName = allergenItems.map((info) => info.name);
      this.allergens = allergensName.join(", ");
    },
    increase: function () {
      this.local_qty += 1;
    },
    decrease: function () {
      if (this.local_qty > 1) {
        this.local_qty -= 1;
      }
    },
    openProductOptions() {
      this.openOptions = true;
    },
    closeProductOptions() {
      this.openOptions = false;
    },
    isVisible(hasAgeGate) {
      if (hasAgeGate === true && !this.getAdult) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
.product_page {
  .image-wrapper {
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
  }
  .qty {
    .btn-qty {
      padding: 0 0 5px 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: map_get($colors, "gray");
      border: 2px solid map_get($colors, "white");
      color: var(--primary-color);
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 0;
      vertical-align: middle;
    }
  }
}
</style>
