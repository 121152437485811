<template>
  <div
    id="page"
    class="d-flex flex-column align-items-center justify-content-center pb-0"
  >
    <div class="my-auto">
      <div class="mt-auto">
        <div
          class="d-flex flex-column w-100 align-items-center pt-5 mt-5 justify-content-center px-2"
        >
          <div
            class="mb-3 mx-auto border-radius-100 bg-color-primary p-10 d-flex align-self-center justify-content-center color-white fs-100 pt-2 w-170px h-170px"
          >
            <img src="@/assets/images/cart-on.svg" width="80" />
          </div>
          <div
            class="text-center color-text-secondary mt-3 fs-30 uppercased"
            v-html="$t(`checkout.thank_you_for_order`)"
          ></div>
        </div>
      </div>
      <div class="w-100 px-4 mt-4">
        <div class="bg-color-gray radius-12 p-3 mx-auto">
          <div
            class="d-flex flex-column w-100 minw-300 align-items-center justify-content-center text-center color-text-secondary"
          >
            <div class="d-flex flex-column justify-content-between w-100">
              <div>
                <h4>{{ $t(`checkout.order_number`) }}</h4>
              </div>
              <div class="font-PB">
                <h1 class="fs-90">{{ this.order_id }}</h1>
              </div>
            </div>
            <div>
              <hr style="background: red" />
            </div>
            <div class="d-flex justify-content-between w-100">
              <div>
                <h4>{{ $t(`product.total_price`) }}:</h4>
              </div>
              <div class="font-PB">
                <h4>{{ formatPrice(sumPrice) }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 row mt-2">
        <div class="col-12">
          <button
            class="btn btn-monty px-5 py-3 color-white font-PR fs-18 w-100"
            slot="button-next"
            @click="goHome"
          >
            {{ $t("action_sheet.ok") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableSessionRepository from "../repositories/TableSessionRepository";
import { mapGetters } from "vuex";
export default {
  name: "CheckoutCompleted",
  data() {
    return {
      order_id: null,
      sumPrice: 0,
    };
  },
  computed: {
    ...mapGetters(["getTableSessionObject", "getLang"]),
  },
  mounted() {
    //this.getSummary();
    console.log(this.getTableSessionObject);
    if (
      this.$route.params.order != null ||
      this.$route.params.order !== undefined
    ) {
      this.order_id = this.$route.params.order.prepaidOrderNumber;
      this.sumPrice = this.$route.params.order.totalPrice;
    }
  },
  methods: {
    async goHome() {
      const isPrinted = confirm("IS TICKET PRINTED?");
      if (isPrinted) {
        const data = {
          tableUuId: this.getTableSessionObject.tableUuId,
        };
        await this.$store.dispatch("resetCart");
        const session = await TableSessionRepository.create(data, (error) => {
          console.log(error);
        });
        await this.$store.dispatch("setSession", session.data);
        console.log(session);
        this.$router.push({ name: "LanguageSelector" });
      } else {
        this.goHome();
      }
    },
    async getSummary() {
      const data = {
        tableIdToSummarize: this.getTableSessionObject.tableId,
        receiptLanguageCode: this.getLang,
      };
      const response = await TableSessionRepository.summary(data);
      console.log(response);
      this.sumPrice = response.sumPrice;
    },
  },
};
</script>
