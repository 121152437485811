<template>
  <!-- <div class="w-100 mx-auto h-100"> -->
  <div class="h-100">
    <router-link
      v-bind:to="{ name: 'Product', params: { id: item.id } }"
      class="color-inherit text-decoration-inherit"
    >
      <div
        class="product-card card-border bg-color-gray radius-12 px-0 pb-3 h-100 d-flex flex-column align-item-center position-relative"
      >
        <!-- <div class="sale-badge">Akció</div> -->
        <div class="text-center w-100">
          <img
            :src="getImgUrl(item.imgURL)"
            width="100%"
            height="auto"
            class="w-100 bordered"
          />
        </div>
        <div
          class="text-center d-flex flex-column justify-content-between align-items-center h-100"
        >
          <p
            class="color-text hover-color-text pt-2 pb-1 font-PR fs-22 font-bold mb-0 lh-25 uppercased"
          >
            {{ item.name }}
          </p>
          <p class="color-light-text pb-3 font-PR mb-0 lh-15">
            {{ item.variation }}
          </p>
          <span class="bg-color-primary color-white fs-18 px-3 py-1 radius-8">
            {{ formatPrice(item.price) }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: ["item"],
  methods: {},
};
</script>

<style lang="scss">
.product-card {
  width: 250px;
}
.bordered {
  border-bottom: 2px solid rgb(221, 221, 221);
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}
</style>
