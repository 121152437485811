<template>
  <div
    class="category-item card-border bg-color-gray radius-12 p-2 h-100 d-flex flex-column align-item-center position-relative"
  >
    <button class="btn" @click="openCategory(item)">
      <div>
        <div class="text-center">
          <img
            :src="getImgUrl(item.coverImageUrl)"
            width="100%"
            height="auto"
            class="maxw-250 rounded-3"
          />
        </div>
        <div
          class="text-center d-flex flex-column justify-content-between align-items-center h-100 pt-2"
        >
          <p
            class="color-text hover-color-text pt-3 pb-1 font-PR fs-22 font-bold mb-0 lh-24 uppercased"
          >
            {{ item.name }}
          </p>
          <p class="color-light-text pb-3 font-PR mb-0 lh-15">
            {{ item.description }}
          </p>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../plugins/event-bus";
export default {
  name: "CategoryItem",
  props: ["item"],
  data() {
    return {
      isAdult: false,
    };
  },
  computed: {
    ...mapGetters(["getAdult"]),
  },
  created() {
    this.isAdult = this.getAdult;
  },
  methods: {
    openCategory: function (item) {
      const route = { name: "Products", params: { id: item.id } };
      this.element = item.id;
      if (item.hasAgeGate) {
        if (this.isAdult) {
          this.$router.push(route);
        } else {
          EventBus.$emit("openAgeGate", route);
        }
      } else {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style lang="scss">
.category-item {
  overflow: hidden;
  background-color: map_get($colors, "gray");
}
</style>
