<template>
  <div
    id="cart-line"
    class="mb-0 mt-auto maxw-600 shadow-top radius-12 bg-white pb-2"
  >
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-center minh-90 pb-3"
    >
      <div class="flex-fill text-center color-white px-4">
        <button
          class="btn btn-block btn-monty w-100 px-2 py-3 color-white font-PR fs-15"
          @click="sheetIsOpen = true"
        >
          {{ $t(`btn.send_order`) }}
        </button>
      </div>
      <div class="flex-fill d-flex flex-column px-4">
        <div
          class="font-PR mb-0 d-inline-block fs-14 color-text-primary text-right"
        >
          {{ $t(`product.total_price`) }}
        </div>
        <div
          class="font-PM mb-0 d-inline-block fs-25 text-color-primary text-right"
        >
          {{ formatPrice(sumPrice) }}
        </div>
      </div>
    </div>
    <OrderSettingsSheet v-model="sheetIsOpen" v-on:sendOrder="order" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderSettingsSheet from "./bottomsheets/OrderSettingsSheet.vue";
export default {
  name: "CartLine",
  components: {
    OrderSettingsSheet,
  },
  computed: {
    ...mapGetters(["getCartSumPrice"]),
  },
  data() {
    return {
      sumPrice: 0,
      sheetIsOpen: false,
    };
  },
  created() {
    this.sumPrice = this.getCartSumPrice;
  },
  methods: {
    async order(value) {
      await this.$store.dispatch("setOrderType", value);
      this.sheetIsOpen = false;
      this.$emit("send-order", true);
    },
  },
  watch: {
    "$store.state.cart": function () {
      this.sumPrice = this.getCartSumPrice;
    },
  },
};
</script>

<style lang="scss">
#cart-line {
  position: fixed;
  width: 100%;
  bottom: 50px;
  z-index: 9;
}
</style>
