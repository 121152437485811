import apiService from "../services/apiService";
import store from "../store";
import { EventBus } from "../plugins/event-bus";

export default {
  searchByKeyword(locationId, searchPhrase) {
    return new Promise((resolve, reject) => {
      apiService
        .get(`product/search/${locationId}/${searchPhrase}`)
        .then((response) =>
          resolve(this.buildDataForSearchByKeyword(response.data))
        )
        .catch((error) => reject(error));
    });
  },
  buildDataForSearchByKeyword(items) {
    const elements = [];
    let hasAgeGate = false;

    if (items?.data && items.data.length > 0) {
      items.data.forEach(function (item) {
        const productInfo = item?.productInfos.find(
          ({ language }) => language === store.state.lang
        );
        const product = {
          id: item.id,
          qty: item.quantity,
          price: item.price,
          unit: productInfo?.unit,
          imgURL: item.productImages[0]?.imageUrl,
          gallery: item.productImages,
          hasAgeGate: item.hasAgeGate,
          name: productInfo?.name,
          desc: productInfo?.description,
          variation: productInfo?.variation,
        };
        if (hasAgeGate === false && item.hasAgeGate) {
          hasAgeGate = true;
        }
        elements.push(product);
      });
    }

    if (hasAgeGate) {
      EventBus.$emit("openAgeGate", true);
    }

    return elements;
  },
};
