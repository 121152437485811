<template>
  <div class="pt-3 pb-1">
    <!-- <div v-if="label != null">
      <p class="font-PM fs-18 mb-1 ps-1">{{ label }}</p>
    </div> -->
    <div class="bg-color-gray radius-12 p-2 d-flex flex-row input-border">
      <div class="flex-fill">
        <input
          class="w-100 search-input ps-3"
          v-model="keywords"
          :placeholder="$t('search_box.placeholder.item')"
          type="text"
        />
      </div>
      <div class="flex-shrink">
        <button @click="search" class="btn btn-monty color-white px-4 py-2">
          {{ $t("btn.search") }}
        </button>
      </div>
    </div>
    <div v-if="showError" class="mt-2 fs-14 color-warning">
      {{ $t("search_box.error.minimal_3_character") }}
    </div>
  </div>
</template>
<script>
export default {
  name: "search-box",
  props: ["value", "label"],
  data() {
    return {
      showError: false,
    };
  },
  computed: {
    keywords: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    keywords: function () {
      this.$emit("input", this.keywords);
    },
  },
  methods: {
    search: function () {
      if (this.keywords.length < 3 && this.keywords.length > 0) {
        this.showError = true;
      } else {
        this.showError = false;
        this.$emit("searchAction", this.keywords);
      }
    },
  },
};
</script>
